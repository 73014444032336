import "./index.scss";
import { AgentOutCardDataProps } from "../../common/commonData";
import React, { useState, useEffect } from "react";
import AgentOutCardCode from "../../component/AgentOutCardCode";
import AgentOutCardDocument from "../../component/AgentOutCardDocument";
import AgentOutCardKnowledgeGraph from "../../component/AgentOutCardGraph";
import AgentOutCardASTGraph from "../../component/AgentOutCardASTGraph";
import AgentOutCardText from "../../component/AgentOutCardText";
import AgentOutCardFile from "../../component/AgentOutCardFile";
import PMMarkdown from "../PMMarkdown";

const AgentOutContainer: React.FC<AgentOutCardDataProps> = ({
  data,
  value,
  allString,
  copyAction
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const bytePrintingList = ["text", "table", "reference", "document", "latex"];
  useEffect(() => {
    if (bytePrintingList.indexOf(data.type) < 0) {
      setHasLoaded(true);
    } else {
      setHasLoaded(!(data.newData || false));
    }
    if (value == allString) {
      setHasLoaded(true);
    }
  }, []);

  const agentMiniContainer = () => {
    let contentDiv = <div></div>;
    if (data.type === "text") {
      contentDiv = (
        <AgentOutCardText
          key={data.id}
          data={data}
          value={value}
          copyAction={copyAction}
        ></AgentOutCardText>
      );
    } else if (data.type === "file") {
      contentDiv = (
        <AgentOutCardFile
          key={data.id}
          data={data}
          value={value}
        ></AgentOutCardFile>
      );
    } else if (data.type === "graph") {
      contentDiv = (
        <AgentOutCardKnowledgeGraph
          key={data.id}
          data={data}
          value={value}
          copyAction={copyAction}
        ></AgentOutCardKnowledgeGraph>
      );
    } else if (data.type === "astGraph") {
      contentDiv = (
        <AgentOutCardASTGraph
          key={data.id}
          data={data}
          value={value}
          copyAction={copyAction}
        ></AgentOutCardASTGraph>
      );
    } else if (data.type === "meta" || data.type === "chunk") {
      contentDiv = (
        <AgentOutCardDocument
          key={data.id}
          data={data}
          value={value}
          copyAction={copyAction}
        ></AgentOutCardDocument>
      );
    } else if (data.type === "code") {
      contentDiv = (
        <AgentOutCardCode
          key={data.id}
          data={data}
          value={value}
          copyAction={copyAction}
        ></AgentOutCardCode>
      );
    } else if (data.type === "div") {
      const newData = data.data.replace(
        "src=",
        ' width="200" height="200" src='
      );
      contentDiv = (
        <div key={data.id}>
          <div dangerouslySetInnerHTML={{ __html: newData }} />
        </div>
      );
    } else if (data.type === "html") {
      contentDiv = (
        <div key={data.id}>
          <div dangerouslySetInnerHTML={{ __html: data.value }} />
        </div>
      );
    } else if (data.type === "markdown") {
      contentDiv = (
        <PMMarkdown key={data.id} markdown={data.value}></PMMarkdown>
      );
    } else {
      contentDiv = <div key={data.id}>{value}</div>;
    }
    return contentDiv;
  };

  //显示的内容
  const getShowContent = () => {
    if (data.content) {
      return data.content;
    }
    if (data.data) {
      return data.data;
    }
    if (data.files && data.files.length > 0) {
      let str = "";
      data.files.forEach((data: any) => {
        str =
          str +
          "******" +
          data.content +
          "******" +
          data.description +
          "******";
      });
      return str;
    }
  };

  return (
    <div id={data.id + Math.random() * 1000}>
      {hasLoaded ? (
        agentMiniContainer()
      ) : (
        <div content={getShowContent()}></div>
      )}
    </div>
  );
};

export default AgentOutContainer;
