import React, { useEffect, useRef } from "react";
import mermaid from "mermaid";

const Mermaid = (props: any) => {
  const { className, data } = props;
  const chartRef = useRef<any>(null);

  useEffect(() => {
    if (chartRef.current) {
      mermaid.contentLoaded();
    }
  }, [data]);

  return (
    <div>
      <div ref={chartRef} className={`mermaid ${className}`}>
        {data}
      </div>
    </div>
  );
};

export default Mermaid;
