import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Input, Form, message, Select, Tabs, Space } from "antd";
import {
  Api,
  Constant,
  SendGetRequest,
  SendPostRequest,
  SendPatchRequest,
  Utils
} from "@/common";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import type { TabsProps } from "antd";
import { Store } from "@/common";
import {
  setOpenId,
  setWechatAvatar,
  setWechatName
} from "@/common/slices/userSlice";
import { addTrackingAction } from "@/common/trackingUtil";
import JsEncrypt from "jsencrypt";
import wechatUtil from "./common/wechatUtil";
import wechatIcon from "@/assets/icons/wechat-icon.png";
import loginBackground from "@/assets/images/login-background.png";
import countryJson from "./common/countryCodeAndPhoneCode.json";
import logo from "@/assets/images/sider_logo.png";
import check from "@/assets/icons/check.png";
import sepLine from "@/assets/icons/sepLine.png";
import companyNameAI from "@/assets/icons/company_name_ai.png";
import companyNamePrismer from "@/assets/icons/company_name_prismer.png";
import wechatQRCode from "@/assets/icons/qr-scan.png";
import "./index.scss";

enum UserAuthPageType {
  register,
  login,
  resetPassword,
  registerWechat,
  bindMobile,
  loginWechat
}

const UserAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [isSendCodeDisabled, setIsSendCodeDisabled] = useState(true);
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);
  const countRef = useRef<number>(-1);
  const [count, setCount] = useState<number>(-1);
  const [countryCodeOptions, setCountryCodeOptions] = useState<any[]>([]);
  const [tabIndex, setTabIndex] = useState("0");
  const [isPhone, setIsPhone] = useState(false);
  const consentAgreementRef = useRef<boolean>(false);
  const [consentAgreement, setconsentAgreement] = useState(false);
  const loginSuccessMsg = "Login successful";
  const checkMobileHint = "Please enter the correct phone number";
  const checkCodeHint = "Please enter the verification code";
  const checkPwdHint = "Please enter a valid password";
  const checkConfirmPwdHint = "The two passwords are different";
  const passwordHint =
    "Password must be at least 8 characters, including uppercase, lowercase letters and numbers.";
  const items: TabsProps["items"] = [
    {
      key: "0",
      label: "SMS Login"
    },
    {
      key: "1",
      label: "Account Login"
    }
  ];
  const [countryData, setCountryData] = useState<any>({
    label: "(+86) China",
    value: "86"
  });
  const mobileRegex = /^1[3-9]\d{9}$/;
  const codeRegex = /^\d{6}$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,16}$/;

  useEffect(() => {
    //处理国家数据
    const handleContryInfo = () => {
      const newArr: any[] = [];
      countryJson.forEach((item) => {
        const data = {
          value: item.phone_code,
          label: "(+" + item.phone_code + ") " + item.english_name
        };
        newArr.push(data);
        if (item.country_code == "CN") {
          setCountryData(data);
        }
      });
      setCountryCodeOptions(newArr);
    };

    //获取公钥接口
    const getPublicKeyApi = () => {
      SendGetRequest(Api.puk, {}).then((res: any) => {
        const { status, data } = res;
        if (status.code === Constant.HTTP_STATUS.SUCCESS) {
          localStorage.setItem("publicKey", data);
        }
      });
    };

    const checkIsMobile = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isPhone =
        /iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
          userAgent
        );
      setIsPhone(isPhone);
    };

    const getWechatQrCodeUrl = () => {
      SendGetRequest(Api.loginWechat, {}).then((res: any) => {
        const { status, data } = res;
        if (status.code === Constant.HTTP_STATUS.SUCCESS) {
          const url = new URL(data.qrCodeUrl);
          const params: any = new URLSearchParams(url.search);
          /**
           * 加密网站
           * https://tool.oschina.net/encrypt?type=3
           * 目前样式
            .impowerBox .qrcode {width: 150px;border:none}
            .impowerBox .title {display: none;}
            .impowerBox .info {display: none;}
            .status_icon {display: none}
            .impowerBox .status {text-align: center;}
           */
          const href =
            "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE1MHB4O2JvcmRlcjpub25lfQouaW1wb3dlckJveCAudGl0bGUge2Rpc3BsYXk6IG5vbmU7fQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZTt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30=";
          const newData = {
            self_redirect: params.get("self_redirect"),
            id: "login_container",
            appid: params.get("appid"),
            scope: params.get("scope"),
            redirect_uri: params.get("redirect_uri"),
            state: params.get("state"),
            style: params.get("style"),
            href: href
          };
          wechatUtil.WxLogin(newData);
        } else {
          message.error(status.msg);
        }
      });
    };

    //初始化数据
    checkIsMobile();
    if (
      pageType == UserAuthPageType.register ||
      pageType == UserAuthPageType.login ||
      pageType == UserAuthPageType.resetPassword
    ) {
      handleContryInfo();
      getPublicKeyApi();
    } else if (
      pageType == UserAuthPageType.loginWechat ||
      pageType == UserAuthPageType.registerWechat
    ) {
      const code = Utils.getQueryParam("code", window.location.href);
      if (code) {
        const state = Utils.getQueryParam("state", window.location.href);
        loginWechatApi(code, state);
      } else {
        getWechatQrCodeUrl();
      }
    } else if (pageType == UserAuthPageType.bindMobile) {
      handleContryInfo();
    }
  }, []);

  const pageType = useMemo(() => {
    if (location.pathname === "/register") {
      return UserAuthPageType.register;
    } else if (location.pathname === "/resetPassword") {
      return UserAuthPageType.resetPassword;
    } else if (location.pathname === "/loginWechat") {
      return UserAuthPageType.loginWechat;
    } else if (location.pathname === "/registerWechat") {
      return UserAuthPageType.registerWechat;
    } else if (location.pathname === "/bindMobile") {
      return UserAuthPageType.bindMobile;
    } else {
      return UserAuthPageType.login;
    }
  }, [location.pathname]);

  const tapLoginLink = () => {
    gotoPage("/login");
  };

  const tapSignUpLink = () => {
    gotoPage("/register");
  };

  const onChange = (key: string) => {
    setTabIndex(key);
  };

  useEffect(() => {
    checkBottomBtnEnable();
  }, [tabIndex]);

  // 加密密码
  const encryptPwd = (pwd: string) => {
    const rsa = new JsEncrypt();
    const publicKey = localStorage.getItem("publicKey");
    if (publicKey) {
      const key = atob(publicKey);
      rsa.setPublicKey(key);
      const newPwd = rsa.encrypt(pwd);
      return newPwd;
    } else {
      return null;
    }
  };

  const onClickSendCode = () => {
    const phone = form.getFieldValue("phone");
    if (!mobileRegex.test(phone)) {
      message.error(checkMobileHint);
    }
    let type = "";
    if (pageType === UserAuthPageType.register) {
      type = "register";
    } else if (pageType === UserAuthPageType.login) {
      type = "login";
    } else if (pageType === UserAuthPageType.resetPassword) {
      type = "resetPassword";
    } else if (pageType === UserAuthPageType.bindMobile) {
      type = "bindPhone";
    }
    SendPostRequest(Api.verifyPhone, {
      phone: phone,
      type: type
    }).then((res: any) => {
      const { status } = res;
      if (status.code === Constant.HTTP_STATUS.SUCCESS) {
        message.success("Send verfiy code success");
        countRef.current = 61;
        cutDown();
      } else {
        message.error(status.desc);
      }
    });
  };

  const cutDown = () => {
    setTimeout(() => {
      if (countRef.current <= 0) {
        return;
      }
      countRef.current = countRef.current - 1;
      setCount(countRef.current);
      setIsSendCodeDisabled(countRef.current > 0);
      cutDown();
    }, 1000);
  };

  const handleCountryCodeChange = (e: any) => {
    const index = countryCodeOptions.findIndex((item: any) => item.value === e);
    if (index > -1) {
      const data = countryCodeOptions[index];
      setCountryData(data);
    }
  };

  const onClickUserAgreement = () => {
    const userAgreementUrl =
      "http://192.168.31.5:6767/Prismer%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.pdf";
    window.open(userAgreementUrl, "_blank");
  };

  const onClickPrivacyPolicy = () => {
    const privacyPolicyUrl =
      "http://192.168.31.5:6767/Prismer%E9%9A%90%E7%A7%81%E6%9D%A1%E6%AC%BE.pdf";
    window.open(privacyPolicyUrl, "_blank");
  };

  const onClickForgotPwd = () => {
    gotoPage("/resetPassword");
  };

  const onClickWechat = () => {
    if (!consentAgreement) {
      message.error("Please agree to the User Agreement and Privacy Policy");
      return;
    }
    if (pageType == UserAuthPageType.login) {
      gotoPage("/loginWechat");
    } else {
      gotoPage("/registerWechat");
    }
  };

  const validateMobile = (rule: any, value: any, callback: any) => {
    if (!mobileRegex.test(value)) {
      callback("Invalid phone number ");
    } else {
      callback();
    }
  };

  const validateCode = (rule: any, value: any, callback: any) => {
    if (!codeRegex.test(value)) {
      callback("Invalid verification code");
    } else {
      callback();
    }
  };

  const validatePwd = (rule: any, value: any, callback: any) => {
    if (!value || !passwordRegex.test(value)) {
      callback(passwordHint);
    } else {
      callback();
    }
  };

  const validateLoginPwd = (rule: any, value: any, callback: any) => {
    if (!value) {
      callback(passwordHint);
    } else {
      callback();
    }
  };

  const validateConfirmPwd = (rule: any, value: any, callback: any) => {
    if (!value || !passwordRegex.test(value)) {
      callback(passwordHint);
    } else {
      callback();
    }
  };

  const handleMobileChange = (e: any) => {
    if (mobileRegex.test(e.target.value)) {
      setIsSendCodeDisabled(false);
    } else {
      setIsSendCodeDisabled(true);
    }
    checkBottomBtnEnable();
  };

  const handleCodeChange = () => {
    checkBottomBtnEnable();
  };

  const handlePasswordChange = () => {
    checkBottomBtnEnable();
  };

  const handleConfirmPasswordChange = () => {
    checkBottomBtnEnable();
  };

  //底部按钮是否可交互
  const checkBottomBtnEnable = () => {
    const formValue = form.getFieldsValue();
    const { phone, code, password, confirmPassword } = formValue;

    //登录
    if (pageType === UserAuthPageType.login) {
      if (!mobileRegex.test(phone) || !consentAgreementRef.current) {
        setIsContinueDisabled(true);
        return;
      }
      if (tabIndex == "0" && codeRegex.test(code)) {
        setIsContinueDisabled(false);
        //登录时候校验密码存在即可
      } else if (tabIndex == "1" && password) {
        setIsContinueDisabled(false);
      } else {
        setIsContinueDisabled(true);
      }
    } else if (pageType === UserAuthPageType.register) {
      //注册
      if (!mobileRegex.test(phone) || !consentAgreementRef.current) {
        setIsContinueDisabled(true);
        return;
      }
      if (
        codeRegex.test(code) &&
        passwordRegex.test(password) &&
        password === confirmPassword
      ) {
        setIsContinueDisabled(false);
      } else {
        setIsContinueDisabled(true);
      }
    } else if (pageType === UserAuthPageType.resetPassword) {
      //重置密码
      if (!mobileRegex.test(phone)) {
        setIsContinueDisabled(true);
        return;
      }
      if (
        codeRegex.test(code) &&
        passwordRegex.test(password) &&
        password === confirmPassword
      ) {
        setIsContinueDisabled(false);
      } else {
        setIsContinueDisabled(true);
      }
    } else if (pageType === UserAuthPageType.registerWechat) {
      //微信注册
      setIsContinueDisabled(false);
    } else if (pageType === UserAuthPageType.bindMobile) {
      //绑定手机
      if (!mobileRegex.test(phone) || !consentAgreementRef.current) {
        setIsContinueDisabled(true);
        return;
      }
      if (codeRegex.test(code)) {
        setIsContinueDisabled(false);
      } else {
        setIsContinueDisabled(true);
      }
    } else {
      setIsContinueDisabled(false);
    }
  };

  const onClickRegisterContinue = () => {
    const values = form.getFieldsValue();
    const phone = values.phone;
    if (!phone || phone.length != 11) {
      message.error(checkMobileHint);
      return;
    }

    const code = values.code;
    if (!code || code.length != 6) {
      message.error(checkCodeHint);
      return;
    }
    const pwd = values.password;
    if (!passwordRegex.test(pwd)) {
      message.error(checkPwdHint);
      return;
    }
    const cpwd = values.confirmPassword;
    if (pwd !== cpwd) {
      message.error(checkConfirmPwdHint);
      return;
    }
    const encryPwd = encryptPwd(pwd);
    if (encryPwd) {
      registerApi(phone, encryPwd || "", code);
    }
  };

  const onClickResetPassword = () => {
    const values = form.getFieldsValue();
    const phone = values.phone;
    if (!phone || phone.length != 11) {
      message.error(checkMobileHint);
      return;
    }

    const code = values.code;
    if (!code || code.length != 6) {
      message.error(checkCodeHint);
      return;
    }
    const pwd = values.password;
    if (!passwordRegex.test(pwd)) {
      message.error(checkPwdHint);
      return;
    }
    const cpwd = values.confirmPassword;
    if (pwd !== cpwd) {
      message.error(checkConfirmPwdHint);
      return;
    }
    const encryPwd = encryptPwd(pwd);
    if (encryPwd) {
      resetPasswordApi(phone, encryPwd || "", code);
    }
  };

  const onClickLogin = () => {
    const values = form.getFieldsValue();
    const phone = values.phone;
    if (!phone || phone.length != 11) {
      message.error(checkMobileHint);
      return;
    }

    if (tabIndex == "0") {
      const code = values.code;
      if (!code || code.length != 6) {
        message.error(checkCodeHint);
        return;
      }
      loginApi(phone, null, code);
    } else {
      const pwd = values.password;
      if (!passwordRegex.test(pwd)) {
        message.error(checkPwdHint);
        return;
      }
      const encryPwd = encryptPwd(pwd);
      if (encryPwd) {
        loginApi(phone, encryPwd, null);
      }
    }
  };

  const onClickBindMobileContinue = () => {
    const values = form.getFieldsValue();
    const phone = values.phone;
    if (!phone || phone.length != 11) {
      message.error(checkMobileHint);
      return;
    }

    const code = values.code;
    if (!code || code.length != 6) {
      message.error(checkCodeHint);
      return;
    }
    wechatBindPhoneApi(phone, code);
  };

  const registerApi = (
    phone: string,
    password: string,
    phoneVerificationCode: string
  ) => {
    SendPostRequest(Api.register, {
      phone: phone,
      password: password,
      phoneVerificationCode: phoneVerificationCode
      // countryCode: countryData.phone_code
    }).then((res: any) => {
      const { status, data } = res;
      //注册即登录
      if (status.code === Constant.HTTP_STATUS.SUCCESS) {
        localStorage.setItem(Constant.LOCAL_KEY.token, data.token);
        localStorage.setItem(Constant.LOCAL_KEY.userId, data.userId);
        addTrackingAction("signup_success");
        bindInviCode();
        gotoPage("/createProfile");
      } else {
        message.error(status.desc || "error");
      }
    });
  };

  const resetPasswordApi = (
    phone: string,
    password: string,
    phoneVerificationCode: string
  ) => {
    SendPatchRequest(Api.register, {
      phone: phone,
      password: password,
      phoneVerificationCode: phoneVerificationCode
      // countryCode: countryData.phone_code
    }).then((res: any) => {
      const { status } = res;
      if (status.code === Constant.HTTP_STATUS.SUCCESS) {
        message.success("reset password success");
        gotoPage(-1);
      } else {
        message.error(status.desc || "error");
      }
    });
  };

  const loginApi = (
    phone: string,
    password: any,
    phoneVerificationCode: any
  ) => {
    const url =
      tabIndex == "0" ? Api.loginWithVerification : Api.loginWithPassword;
    const params =
      tabIndex == "0"
        ? {
            phone: phone,
            phoneVerificationCode: phoneVerificationCode
            // countryCode: countryData.phone_code
          }
        : {
            phone: phone,
            password: password
            // countryCode: countryData.phone_code
          };

    SendPostRequest(url, params).then((res: any) => {
      const { status, data } = res;
      if (status.code === Constant.HTTP_STATUS.SUCCESS) {
        localStorage.setItem(Constant.LOCAL_KEY.token, data.token);
        localStorage.setItem(Constant.LOCAL_KEY.userId, data.userId);
        message.success(loginSuccessMsg);
        addTrackingAction("login_success");
        bindInviCode();
        gotoPage("/");
      } else {
        message.error(status.desc || "error");
      }
    });
  };

  const loginWechatApi = (code: any, state: any) => {
    if (!code || !state) {
      return;
    }

    SendPostRequest(Api.loginWechat, {
      code: code,
      state: state
    }).then((res: any) => {
      const { status, data } = res;
      if (status.code === Constant.HTTP_STATUS.SUCCESS) {
        localStorage.setItem(Constant.LOCAL_KEY.token, data.token);
        localStorage.setItem(Constant.LOCAL_KEY.userId, data.userId);
        message.success(loginSuccessMsg);
        addTrackingAction("login_success");
        bindInviCode();
        gotoPage("/");
      } else if (status.code === Constant.HTTP_STATUS.BIND_PHONE) {
        Store.dispatch(setOpenId(data.openid));
        gotoPage("/bindMobile");
      } else {
        message.error(status.desc || "error");
      }
    });
  };

  const gotoPage = (page: any, redirect?: boolean) => {
    if (redirect) {
      navigate(page);
      return;
    }
    if (page === "/") {
      const list: any[] = Store.getState().commonSlice.loginRouteHistoryList;
      for (let i = list.length - 1; i >= 0; i--) {
        if (Utils.isUserAuthPage(list[i])) {
          navigate(-1);
        } else {
          break;
        }
      }
      return;
    }
    navigate(page);
  };

  const wechatBindPhoneApi = (phone: string, phoneVerificationCode: any) => {
    const url = Api.wechatBindPhone;
    const params = {
      phone: phone,
      phoneVerificationCode: phoneVerificationCode,
      openid: Store.getState().userSlice.openid
    };
    SendPatchRequest(url, params).then((res: any) => {
      const { status, data } = res;
      if (status.code === Constant.HTTP_STATUS.SUCCESS) {
        localStorage.setItem(Constant.LOCAL_KEY.token, data.token);
        localStorage.setItem(Constant.LOCAL_KEY.userId, data.userId);
        if (data.newUser == true) {
          Store.dispatch(setWechatAvatar(data.avatar));
          Store.dispatch(setWechatName(data.name));
          bindInviCode();
          addTrackingAction("signup_success");
          gotoPage("/createProfile");
        } else {
          message.success(loginSuccessMsg);
          addTrackingAction("login_success");
          gotoPage("/");
        }
      } else {
        message.error(status.desc || "error");
      }
    });
  };

  const bindInviCode = () => {
    Utils.bindInviCode();
  };

  const stepSection = () => {
    let title = "";
    if (pageType === UserAuthPageType.register) {
      title = "Step 1 of 3";
    }
    return <div className="sign-in-box-step">{title}</div>;
  };

  const titleSection = () => {
    let title = "";
    if (
      pageType === UserAuthPageType.register ||
      pageType === UserAuthPageType.registerWechat
    ) {
      title = "Create your account";
    } else if (pageType === UserAuthPageType.bindMobile) {
      title = "Bind your phone";
    } else if (pageType === UserAuthPageType.resetPassword) {
      title = "Reset your password";
    } else if (
      pageType === UserAuthPageType.login ||
      pageType === UserAuthPageType.loginWechat
    ) {
      title = "Welcome back";
    }
    return <h4 className="sign-in-box-title">{title}</h4>;
  };

  const subTitleSection = () => {
    let title = "";
    let span = null;
    if (pageType === UserAuthPageType.login) {
      title = "Don't have an account?";
      span = <span onClick={tapSignUpLink}> Sign up</span>;
    } else if (pageType === UserAuthPageType.resetPassword) {
      title = "Know your password?";
      span = <span onClick={tapLoginLink}> Log in</span>;
    } else if (pageType === UserAuthPageType.loginWechat) {
      title = "login by account or mobile?";
      span = (
        <span
          onClick={() => {
            gotoPage(-1);
          }}
        >
          {" "}
          Go back
        </span>
      );
    } else {
      title = "Already have an account?";
      span = <span onClick={tapLoginLink}> Log in</span>;
    }
    return (
      <div className="sign-in-box-subtitle">
        {title}
        {span}
      </div>
    );
  };

  const phoneDesSection = () => {
    let content = "";
    if (pageType === UserAuthPageType.register) {
      content = "Phone Registration";
    } else if (pageType === UserAuthPageType.resetPassword) {
      content = "Phone number";
    } else if (
      pageType === UserAuthPageType.registerWechat ||
      pageType === UserAuthPageType.bindMobile
    ) {
      content = "Wechat Registration";
    }
    return <div className="sign-in-box-des">{content}</div>;
  };

  const phoneSection = () => {
    return (
      <div className="sign-in-box-phone">
        <Form.Item
          name="phone"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Please enter the correct phone number.",
              validator: validateMobile
            }
          ]}
        >
          <div
            style={{
              border: "1px solid #d9d9d9",
              borderRadius: "12px",
              padding: "0 0 0 30px"
            }}
          >
            <Space.Compact>
              <Select
                defaultValue={countryData.label}
                options={countryCodeOptions}
                onChange={handleCountryCodeChange}
              />
              <Input
                placeholder="Please enter your phone number"
                onChange={handleMobileChange}
                maxLength={11}
                style={{
                  textAlign: "left",
                  border: "none",
                  padding: "0 10px 0 30px"
                }}
              />
            </Space.Compact>
          </div>
        </Form.Item>
      </div>
    );
  };

  const codeSection = () => {
    if (tabIndex == "1") {
      return null;
    }
    return (
      <div className="sign-in-box-code">
        <Form.Item
          name="code"
          validateTrigger="onBlur"
          rules={[{ required: true, validator: validateCode }]}
        >
          <Input
            placeholder="Please enter the verification code"
            onChange={handleCodeChange}
          />
        </Form.Item>
        <Button
          onClick={onClickSendCode}
          className={isSendCodeDisabled ? "disabled-button" : "enabled-button"}
          disabled={isSendCodeDisabled}
        >
          {count > 0 && countRef.current > 0
            ? `${countRef.current} s`
            : isPhone
              ? "Send code"
              : "Send verification code"}
        </Button>
      </div>
    );
  };

  const pwdSection = () => {
    if (pageType == UserAuthPageType.login && tabIndex == "0") {
      return null;
    }
    return (
      <Form.Item
        name="password"
        validateTrigger="onBlur"
        rules={[
          pageType == UserAuthPageType.login
            ? { required: true, validator: validateLoginPwd }
            : { required: true, validator: validatePwd }
        ]}
        style={{ marginTop: "30px" }}
      >
        <Input.Password
          placeholder="Please enter your password"
          autoComplete="off"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          onChange={handlePasswordChange}
        />
      </Form.Item>
    );
  };

  const confirmPwdSection = () => {
    return (
      <Form.Item
        name="confirmPassword"
        validateTrigger="onBlur"
        rules={[{ required: true, validator: validateConfirmPwd }]}
      >
        <Input.Password
          placeholder="Please enter your password again"
          autoComplete="off"
          onChange={handleConfirmPasswordChange}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
    );
  };

  const contentPage = () => {
    if (pageType === UserAuthPageType.register) {
      return registerContent();
    } else if (pageType === UserAuthPageType.resetPassword) {
      return resetPwdContent();
    } else if (
      pageType === UserAuthPageType.registerWechat ||
      pageType === UserAuthPageType.loginWechat
    ) {
      return wechatContent();
    } else if (pageType === UserAuthPageType.bindMobile) {
      return bindMobileContent();
    } else {
      return loginContent();
    }
  };

  const bottomBtn = () => {
    let btnTitle = "";
    let func: any = undefined;
    if (pageType === UserAuthPageType.register) {
      btnTitle = "Continue";
      func = onClickRegisterContinue;
    } else if (pageType === UserAuthPageType.resetPassword) {
      btnTitle = "Reset password";
      func = onClickResetPassword;
    } else if (pageType === UserAuthPageType.registerWechat) {
      btnTitle = "Continue";
    } else if (pageType === UserAuthPageType.bindMobile) {
      btnTitle = "Continue";
      func = onClickBindMobileContinue;
    } else {
      btnTitle = "Login";
      func = onClickLogin;
    }

    return (
      <div className="sign-in-box-ensure">
        <Button
          type="primary"
          onClick={() => {
            if (func) {
              func();
            }
          }}
          disabled={isContinueDisabled}
          className={isContinueDisabled ? "disabled-button" : "enabled-button"}
        >
          {btnTitle}
        </Button>
      </div>
    );
  };

  const cancelBtn = () => {
    return (
      <div className="cancel">
        <Button
          type="primary"
          onClick={() => {
            gotoPage(-1);
          }}
          className="enabled-button"
        >
          Cancel
        </Button>
      </div>
    );
  };

  const userAgreementSection = () => {
    if (pageType === UserAuthPageType.resetPassword) {
      return null;
    }
    return (
      <div className="user-agreement-terms">
        <div
          className="user-agreement-terms-check"
          onClick={() => {
            consentAgreementRef.current = !consentAgreementRef.current;
            setconsentAgreement(consentAgreementRef.current);
            checkBottomBtnEnable();
          }}
        >
          {consentAgreement ? (
            <img src={check} width={15} height={15}></img>
          ) : null}
        </div>
        <div>I have read and agree to the</div>
        <div className="link" onClick={onClickUserAgreement}>
          &nbsp;User Agreement&nbsp;
        </div>
        <div>&</div>
        <div className="link" onClick={onClickPrivacyPolicy}>
          &nbsp;Privacy Policy
        </div>
        <div className="termsSpace"></div>
        {pageType == UserAuthPageType.login && tabIndex == "1" ? (
          <div className="forgetPwd" onClick={onClickForgotPwd}>
            Forgot Password?
          </div>
        ) : null}
      </div>
    );
  };

  const wechatSep = () => {
    return (
      <div className="sign-in-box-sep">
        <img src={sepLine} width={"100%"}></img>
      </div>
    );
  };

  const wechatButton = () => {
    let title = "";
    if (pageType == UserAuthPageType.register) {
      title = "Sign up with WeChat";
    } else if (pageType == UserAuthPageType.login) {
      title = "Login with WeChat";
    }
    return (
      <div className="sign-in-box-wechat">
        <Button
          icon={<img src={wechatIcon} width={20} />}
          onClick={onClickWechat}
        >
          {title}
        </Button>
      </div>
    );
  };

  const wechatQR = () => {
    return (
      <div className="sign-in-box-wechat-container">
        <div id="login_container"></div>
        <div className="sign-in-box-wechat-container-des">
          <img src={wechatQRCode} width={20} />
          <div>Use WeChat to scan the QR code</div>
        </div>
      </div>
    );
  };

  const spaceSection = () => {
    return <div style={{ height: "100px" }}></div>;
  };

  const loginContent = () => {
    return (
      <div className="sign-in-box">
        {stepSection()}
        {titleSection()}
        {subTitleSection()}
        <div className="sign-in-box-tab">
          <Tabs defaultActiveKey="0" items={items} onChange={onChange} />
        </div>
        <Form form={form} layout="vertical" autoComplete="off">
          {phoneSection()}
          {codeSection()}
          {pwdSection()}
        </Form>
        {bottomBtn()}
        {userAgreementSection()}
        {wechatSep()}
        {wechatButton()}
        {spaceSection()}
      </div>
    );
  };

  const registerContent = () => {
    return (
      <div className="sign-in-box">
        {stepSection()}
        {titleSection()}
        {subTitleSection()}
        {phoneDesSection()}
        <Form form={form} layout="vertical" autoComplete="off">
          {phoneSection()}
          {codeSection()}
          {pwdSection()}
          {confirmPwdSection()}
        </Form>
        {bottomBtn()}
        {userAgreementSection()}
        {wechatSep()}
        {wechatButton()}
        {spaceSection()}
      </div>
    );
  };

  const wechatContent = () => {
    return (
      <div className="sign-in-box">
        {stepSection()}
        {titleSection()}
        {subTitleSection()}
        {wechatQR()}
        {spaceSection()}
      </div>
    );
  };

  const bindMobileContent = () => {
    return (
      <div className="sign-in-box">
        {stepSection()}
        {titleSection()}
        {subTitleSection()}
        {phoneDesSection()}
        <Form form={form} layout="vertical" autoComplete="off">
          {phoneSection()}
          {codeSection()}
        </Form>
        <div style={{ height: "160px" }}></div>
        {userAgreementSection()}
        {bottomBtn()}
        {spaceSection()}
      </div>
    );
  };

  const resetPwdContent = () => {
    return (
      <div className="sign-in-box">
        {stepSection()}
        {titleSection()}
        {subTitleSection()}
        {phoneDesSection()}
        <Form form={form} layout="vertical" autoComplete="off">
          {phoneSection()}
          {codeSection()}
          {pwdSection()}
          {confirmPwdSection()}
        </Form>
        {bottomBtn()}
        {cancelBtn()}
        {spaceSection()}
      </div>
    );
  };

  return (
    <div className="sign-in">
      {isPhone ? null : (
        <div className="sign-in-right">
          <img src={loginBackground} width={"100%"} height={"100%"}></img>
          <div className="sign-in-right-content">
            <div className="sign-in-right-content-title">
              <img src={companyNamePrismer} width={260}></img>
              <img src={companyNameAI} width={32}></img>
            </div>
            <div className="sign-in-right-content-subtitle">
              Your AI Research Partner
            </div>
          </div>
        </div>
      )}
      <div className="sign-in-left">
        <img
          src={logo}
          className="sign-in-logo"
          width={195}
          onClick={() => {
            gotoPage("/", true);
          }}
        />
        {contentPage()}
      </div>
    </div>
  );
};

export default UserAuth;

// 根据english_name进行排序
// countryJson.sort((a, b) => {
//   const nameA = a.english_name.toUpperCase(); // 升序排序，将所有字母转换为大写以忽略大小写差异
//   const nameB = b.english_name.toUpperCase();
//   if (nameA < nameB) {
//     return -1;
//   }
//   if (nameA > nameB) {
//     return 1;
//   }
//   return 0; // 如果名字相同，则保持不变
// });
// console.log(countryJson);
