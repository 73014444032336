import React from "react";
import "./index.scss";
import empty from "@/assets/images/empty.png";

const Empty = () => {
  return (
    <div className="empty">
      <img src={empty} />
      <p>empty content</p>
    </div>
  );
};

export default Empty;
