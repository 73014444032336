// !(function (e, t) {
//   e.WxLogin = function (e) {
//     var r = "default";
//     !0 === e.self_redirect
//       ? (r = "true")
//       : !1 === e.self_redirect && (r = "false");
//     var i = t.createElement("iframe"),
//       n =
//         "https://open.weixin.qq.com/connect/qrconnect?appid=" +
//         e.appid +
//         "&scope=" +
//         e.scope +
//         "&redirect_uri=" +
//         e.redirect_uri +
//         "&state=" +
//         e.state +
//         "&login_type=jssdk&self_redirect=" +
//         r +
//         "&styletype=" +
//         (e.styletype || "") +
//         "&sizetype=" +
//         (e.sizetype || "") +
//         "&bgcolor=" +
//         (e.bgcolor || "") +
//         "&rst=" +
//         (e.rst || "");
//     (n += e.style ? "&style=" + e.style : ""),
//       (n += e.href ? "&href=" + e.href : ""),
//       (n += "en" === e.lang ? "&lang=en" : ""),
//       (n += 1 === e.stylelite ? "&stylelite=1" : ""),
//       (i.src = n),
//       (i.frameBorder = "0"),
//       (i.allowTransparency = "true"),
//       (i.scrolling = "no"),
//       (i.width = "300px"),
//       (i.height = "400px");
//     var l = t.getElementById(e.id);
//     (l.innerHTML = ""), l.appendChild(i);
//   };
// })(window, document);

// 定义接口来描述WxLogin函数的参数结构
interface IWxLoginOptions {
  appid: string; // 微信小程序 appId
  scope: string; // 应用授权作用域
  redirect_uri: string; // 授权后重定向的uri
  state?: string; // 重定向后会带上state参数
  login_type?: "jssdk"; // 登录方式，默认为jssdk
  self_redirect?: "true" | "false"; // 是否使用自带的redirect
  styletype?: string; // 样式类型
  sizetype?: string; // 大小类型
  bgcolor?: string; // 背景颜色
  rst?: string; // 其他参数
  style?: string; // 样式
  href?: string; // 链接
  lang?: "en"; // 语言
  stylelite?: 1; // 是否使用精简版
  id: string; // 容器元素的id
}

// 定义WxLogin函数，并为其参数添加类型注解
function WxLogin(options: IWxLoginOptions): void {
  const defaultRedirect = "default";
  let selfRedirect = defaultRedirect;

  // 根据options中的self_redirect字段来设置selfRedirect的值
  if (options.self_redirect == "true") {
    selfRedirect = "true";
  } else if (options.self_redirect == "false") {
    selfRedirect = "false";
  }

  // 创建iframe元素
  const iframe: any = document.createElement("iframe");
  let url = `https://open.weixin.qq.com/connect/qrconnect?appid=${options.appid}&scope=${options.scope}&redirect_uri=${options.redirect_uri}&state=${options.state}&login_type=jssdk&self_redirect=${selfRedirect}&styletype=${options.styletype || ""}&sizetype=${options.sizetype || ""}&bgcolor=${options.bgcolor || ""}&rst=${options.rst || ""}`;

  // 根据options添加其他参数
  url += options.style ? `&style=${options.style}` : "";
  url += options.href ? `&href=${options.href}` : "";
  url += options.lang === "en" ? "&lang=en" : "";
  url += options.stylelite === 1 ? "&stylelite=1" : "";

  iframe.src = url;
  iframe.frameBorder = "0";
  iframe.allowTransparency = "true";
  iframe.scrolling = "no";
  iframe.width = "180px";
  iframe.height = "180px";

  // 获取id对应的容器元素，并添加iframe
  const container = document.getElementById(options.id);
  if (container) {
    container.innerHTML = "";
    container.appendChild(iframe);
  }
}

export default {
  WxLogin
};
