import React from "react";
import { useNavigate } from "react-router-dom";
import { Utils } from "@/common";
import { AiBar } from "@/components/";
import "./index.scss";
import logo from "@/assets/images/assistant_logo.png";

const Assistant = () => {
  const navigate = useNavigate();

  const AiBarCallBack = (content: string) => {
    Utils.setLocalStorage("aiBarContent", content);
    navigate("/agentOut");
  };

  return (
    <div className="assistant">
      <img src={logo} className="assistant-logo" />
      <p className="assistant-title">Your AI Research Partner</p>
      <AiBar
        className="assistant-bar"
        align="top"
        showFullScreen={true}
        callBackFun={AiBarCallBack}
      />
    </div>
  );
};

export default Assistant;
