import React from "react";
import { Image } from "antd";
import AiAvatar from "@/assets/icons/ai_avatar.png";
import UserAvatar from "@/assets/icons/user_picture.png";
import refresh from "@/assets/icons/refresh.png";
import copygray from "@/assets/icons/copy-gray.png";

import "./index.scss";

interface AgentOutChatContainerProps {
  node: any;
  data: any;
  onReGenerating?: (data: any) => void;
  onCopy?: (data: any) => void;
  node_title?: string;
  node_event?: string;
}

const AgentOutChatContainer: React.FC<AgentOutChatContainerProps> = ({
  node,
  data,
  node_event,
  node_title,
  onReGenerating,
  onCopy
}) => {
  const getSpaceNode = () => {
    return <div className="space"></div>;
  };

  const getAiAvatarNode = () => {
    return (
      <div className="avatar">
        <Image
          width={35}
          src={AiAvatar}
          alt=""
          preview={false}
          style={{ borderRadius: "17px" }}
        />
      </div>
    );
  };

  const getUserAvatarNode = () => {
    return (
      <div className="avatar">
        <Image
          src={UserAvatar}
          alt=""
          preview={false}
          style={{ borderRadius: "17px" }}
        />
      </div>
    );
  };

  const regeneratingAction = () => {
    if (onReGenerating) {
      onReGenerating(data);
    }
  };

  const copyAction = () => {
    if (onCopy) {
      onCopy(data);
    }
  };

  const bottomNode = () => {
    if (data.length == 0) {
      return null;
    }
    if ((data as any).role === "user") {
      return null;
    }
    return (
      <div>
        {onReGenerating ? (
          <div className="pm-card-content-bottom">
            {data.type == "document" ? null : (
              <Image
                src={copygray}
                width={16}
                preview={false}
                onClick={copyAction}
              ></Image>
            )}
            <Image
              src={refresh}
              width={16}
              preview={false}
              onClick={regeneratingAction}
            ></Image>
          </div>
        ) : null}
      </div>
    );
  };

  const getContentNode = () => {
    return (
      <div className="pm-card-content">
        {(data as any).role === "user" ? null : getAgentNodeInfo()}
        {node}
        {bottomNode()}
      </div>
    );
  };

  const getAgentNodeInfo = () => {
    return (
      <>
        {((node_event && node_event.length > 0) ||
          (node_title && node_title.length > 0)) && (
          <div className="pm-card-content-ai-node">
            <div>{node_event}</div>
            <div>{node_title}</div>
          </div>
        )}
      </>
    );
  };

  const getAiNode = () => {
    return (
      <div className="pm-card-content-ai">
        {getAiAvatarNode()}
        {getContentNode()}
      </div>
    );
  };

  const getUserNode = () => {
    return (
      <div className="pm-card-content-user">
        {getSpaceNode()}
        {getContentNode()}
        {getUserAvatarNode()}
      </div>
    );
  };

  return (
    <div className="pm-card">
      {(data as any).role === "user" ? getUserNode() : getAiNode()}
    </div>
  );
};

export default AgentOutChatContainer;
