import React from "react";
import {
  WeiboOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  TwitterOutlined
} from "@ant-design/icons";

const RelatedIcons = [
  {
    website: "facebook",
    icon: <FacebookOutlined />
  },
  {
    website: "instagram",
    icon: <LinkedinOutlined />
  },
  {
    website: "x",
    icon: <TwitterOutlined />
  },
  {
    website: "weibo",
    icon: <WeiboOutlined />
  }
];

export default RelatedIcons;
