import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  taskList: [],
  stopTaskId: ""
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setTaskList(state, action: PayloadAction<any[]>) {
      state.taskList = action.payload;
    },
    getTaskList(state) {
      return state.taskList;
    },
    addTask(state, action: PayloadAction<any>) {
      if (action.payload && action.payload.taskId) {
        const data = action.payload;
        if (!data.schedule) {
          data.schedule = 0;
        }
        if (data.stop == null) {
          data.stop = false;
        }
        console.log("addTask进行=", data);
        state.taskList.push(data);
      }
    },
    removeTask(state, action: PayloadAction<string>) {
      state.taskList = state.taskList.filter(
        (item: any) => item.taskId !== action.payload
      );
    },
    updateTask(state, action: PayloadAction<any>) {
      const index = state.taskList.findIndex(
        (item: any) => item.taskId == action.payload.taskId
      );
      if (index > -1) {
        state.taskList[index] = action.payload;
      }
    },
    setStopTaskId(state, action: PayloadAction<string>) {
      state.stopTaskId = action.payload;
    }
  }
});

export const {
  getTaskList,
  setTaskList,
  removeTask,
  addTask,
  updateTask,
  setStopTaskId
} = taskSlice.actions;

export default taskSlice.reducer;
