import React from "react";
import UserAuth from "../userAuth";
import "./index.scss";

const Register = () => {
  return (
    <div className="register">
      <UserAuth />;
    </div>
  );
};

export default Register;
