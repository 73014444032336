import Assistant from "./assistant";
import Recommendation from "./recommendation";
import Knowledge from "./knowledge";
import AgentOut from "./agentOut";
import CreateProfile from "./createProfile";
import CreateTag from "./createTag";
import UserProfile from "./userProfile";
import Login from "@/pages/login";
import Register from "@/pages/register";
import RegisterWechat from "@/pages/registerWechat";
import ResetPassword from "@/pages/resetPassword";
import BindMobile from "@/pages/bindMobile";
import NotFound from "./notFound";

export {
  Assistant,
  Recommendation,
  Knowledge,
  AgentOut,
  CreateProfile,
  CreateTag,
  UserProfile,
  Login,
  Register,
  RegisterWechat,
  ResetPassword,
  BindMobile,
  NotFound
};
