// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assistant {
  text-align: center;
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;
}
.assistant-logo {
  max-width: 1.5625rem;
  margin: 0.66146rem 0 0.10417rem 0;
}
.assistant-title {
  font-size: 0.20833rem;
  font-family: Roboto-Thin;
  color: var(--var-prismer-color3);
  margin-top: 0.20833rem;
}
.assistant-bar {
  margin-top: 0.41667rem;
}
.assistant .mark-down {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/assistant/index.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,qBAAA;EACA,sBAAA;EACA,mBAAA;AACF;AAAE;EACE,oBAAA;EACA,iCAAA;AAEJ;AAAE;EACE,qBAAA;EACA,wBAAA;EACA,gCAAA;EACA,sBAAA;AAEJ;AAAE;EACE,sBAAA;AAEJ;AAAE;EACE,WAAA;AAEJ","sourcesContent":[".assistant {\n  text-align: center;\n  display: flex;\n  justify-items: center;\n  flex-direction: column;\n  align-items: center;\n  &-logo {\n    max-width: 300px;\n    margin: 127px 0 20px 0;\n  }\n  &-title {\n    font-size: 40px;\n    font-family: Roboto-Thin;\n    color: var(--var-prismer-color3);\n    margin-top: 40px;\n  }\n  &-bar {\n    margin-top: 80px;\n  }\n  .mark-down {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
