import table1 from "@/assets/icons/data_type_table1.png";
import table2 from "@/assets/icons/data_type_table2.png";
import table3 from "@/assets/icons/data_type_table3.png";
import table4 from "@/assets/icons/data_type_table4.png";
import table5 from "@/assets/icons/data_type_table5.png";
import code1 from "@/assets/icons/data_type_code1.png";
import code2 from "@/assets/icons/data_type_code2.png";
import code3 from "@/assets/icons/data_type_code3.png";
import code4 from "@/assets/icons/data_type_code4.png";
import code5 from "@/assets/icons/data_type_code5.png";
import img1 from "@/assets/icons/data_type_img1.png";
import img2 from "@/assets/icons/data_type_img2.png";
import img3 from "@/assets/icons/data_type_img3.png";
import img4 from "@/assets/icons/data_type_img4.png";
import img5 from "@/assets/icons/data_type_img5.png";
import graph1 from "@/assets/icons/data_type_kg1.png";
import graph2 from "@/assets/icons/data_type_kg2.png";
import graph3 from "@/assets/icons/data_type_kg3.png";
import graph4 from "@/assets/icons/data_type_kg4.png";
import graph5 from "@/assets/icons/data_type_kg5.png";
import latex1 from "@/assets/icons/data_type_latex1.png";
import latex2 from "@/assets/icons/data_type_latex2.png";
import latex3 from "@/assets/icons/data_type_latex3.png";
import latex4 from "@/assets/icons/data_type_latex4.png";
import latex5 from "@/assets/icons/data_type_latex5.png";
import doc1 from "@/assets/icons/data_type_doc1.png";
import doc2 from "@/assets/icons/data_type_doc2.png";
import doc3 from "@/assets/icons/data_type_doc3.png";
import doc4 from "@/assets/icons/data_type_doc4.png";
import doc5 from "@/assets/icons/data_type_doc5.png";
export interface AiBarConfig {
  className?: string;
  placeholder?: string;
  copyData?: any;
  align?: string;
  editable?: boolean;
  showFullScreen?: boolean;
  callBackFun?: (content: string) => void;
  contentChange?: (height: number, hasContinuAskContent: any) => void;
  stopGenerateCallBack?: () => void;
}

const NameAndIcon = [
  {
    name: "table1",
    icon: table1
  },
  {
    name: "table2",
    icon: table2
  },
  {
    name: "table3",
    icon: table3
  },
  {
    name: "table4",
    icon: table4
  },
  {
    name: "table5",
    icon: table5
  },
  {
    name: "latex1",
    icon: latex1
  },
  {
    name: "latex2",
    icon: latex2
  },
  {
    name: "latex3",
    icon: latex3
  },
  {
    name: "latex4",
    icon: latex4
  },
  {
    name: "latex5",
    icon: latex5
  },
  {
    name: "img1",
    icon: img1
  },
  {
    name: "img2",
    icon: img2
  },
  {
    name: "img3",
    icon: img3
  },
  {
    name: "img4",
    icon: img4
  },
  {
    name: "img5",
    icon: img5
  },
  {
    name: "graph1",
    icon: graph1
  },
  {
    name: "graph2",
    icon: graph2
  },
  {
    name: "graph3",
    icon: graph3
  },
  {
    name: "graph4",
    icon: graph4
  },
  {
    name: "graph5",
    icon: graph5
  },
  {
    name: "code1",
    icon: code1
  },
  {
    name: "code2",
    icon: code2
  },
  {
    name: "code3",
    icon: code3
  },
  {
    name: "code4",
    icon: code4
  },
  {
    name: "code5",
    icon: code5
  },
  {
    name: "doc1",
    icon: doc1
  },
  {
    name: "doc2",
    icon: doc2
  },
  {
    name: "doc3",
    icon: doc3
  },
  {
    name: "doc4",
    icon: doc4
  },
  {
    name: "doc5",
    icon: doc5
  }
];

export { NameAndIcon };
