import React, { useState } from "react";
import { Button } from "antd";
import UserAvatar from "@/assets/icons/user_picture.png";
import SignOut from "@/assets/icons/sign-out.png";
import "./index.scss";
import { Constant } from "@/common";

interface UserInfoMenuProps {
  userInfo: any;
  onClickItem?: (title: string) => void;
  onClickOverlay?: () => void;
  onLogout?: () => void;
}

const UserInfoMenu = (props: UserInfoMenuProps) => {
  const [selectIndex, setSelectIndex] = useState(0);
  const itemList: any = [
    {
      value: 0,
      icon: null,
      title: Constant.USER_MENU.UserProfile
    }
    // {
    //   value: 1,
    //   icon: null,
    //   title: Constant.USER_MENU.Setting
    // }
  ];

  //登出
  const logout = () => {
    props.onLogout && props.onLogout();
  };

  const subscriptionToPro = () => {
    //
  };

  const clickOverlay = () => {
    props.onClickOverlay && props.onClickOverlay();
  };

  return (
    <div className="user-info-menu">
      <div className="user-info-overlay" onClick={clickOverlay}>
        <div className="user-info-menu-container">
          <div className="avatar">
            {props.userInfo.avatar ? (
              <img src={props.userInfo.avatar} width={80} height={80}></img>
            ) : (
              <img src={UserAvatar} width={80} height={80}></img>
            )}
          </div>
          <div className="name">
            {props.userInfo.nickname || props.userInfo.name || ""}
          </div>
          {itemList.map((item: any) => {
            return (
              <div
                key={item.value}
                className={
                  item.value === selectIndex ? "selectItem" : "unSelectItem"
                }
                onClick={() => {
                  setSelectIndex(item.value);
                  props.onClickItem && props.onClickItem(item.title);
                }}
              >
                {item.title}
              </div>
            );
          })}
          <div className="place"></div>
          <div className="subPro" onClick={subscriptionToPro}>
            <Button type="primary">Subscription To Pro</Button>
          </div>
          <div className="logout" onClick={logout}>
            <Button icon={<img src={SignOut} width={15} />}>logout</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfoMenu;
