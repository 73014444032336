const TagAndColor = [
  { color: "#5856D6", id: 1 },
  { color: "#34C759", id: 2 },
  { color: "#30B0C7", id: 3 },
  { color: "#FF2D55", id: 4 },
  { color: "#32ADE6", id: 5 },
  { color: "#AF52DE", id: 6 },
  {
    color: "#00C7BE",
    id: 7
  },
  { color: "#FF9500", id: 8 }
];

export default TagAndColor;
