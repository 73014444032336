import { Api } from "@/common";

// url
const handleAgentUrl = () => {
  return Api.chat;
};

// RequestHeader
const handleAgentRequestHeader = () => {
  return {
    "Content-Type": "text/event-stream",
    Accept: "*/*"
    // "Request_id": '123456',
    // "App_id": "prismer@ai",
    // "Trace_id": '123',
    // "Span_id": '123',
  };
};

// 请求参数
const handleAgentQuery = (data: string, conversationId?: string) => {
  return {
    conversation_id: conversationId,
    query: data
    // Intention: "Code Project Generation"
  };
};

const getRandomData = () => {
  const myId = new Date().getTime() + Math.floor(Math.random() * 1000);
  return myId.toString();
};

//去除行首空格
function removeLeadingNewlines(str: string) {
  return str.replace(/^\s+/, "");
}

///解析大模型返回的数据
const handleAgentResult = (data: any) => {
  const res_prismer = handleAgentResultData(data);
  return res_prismer;
};

//处理数据
const handleAgentResultData = (thrunk: any) => {
  // console.log('开始解析thrunk内容是', thrunk);
  if (typeof thrunk != "string") {
    return {};
  }

  try {
    const newThrunk = removeLeadingNewlines(thrunk);
    const index: number = newThrunk.indexOf("data:");
    const dataStr: string = newThrunk.substring(index + 5, newThrunk.length);
    const result = JSON.parse(dataStr);
    // console.log('解析流式数据成功', result);
    let data = {};
    if (result.data.outputs) {
      data = result.data.outputs;
    } else if (result.data) {
      data = result.data;
    }

    //输出
    const resData = {
      node_type: result.data.node_type,
      title: result.data.title,
      outputs: data,
      task_id: result.task_id,
      event: result.event,
      node_id: result.data.node_id,
      predecessor_node_id: result.data.predecessor_node_id
    };
    // console.log('%c进行的阶段===', 'color: blue', result.event, result.data.title, result.data.node_type,);
    return resData;
  } catch (error) {
    return "";
  }
};

//找出完整的thunk
const findCompleteThrunk = (str: string) => {
  const finishIndex = str.trim().indexOf('"event":"finished"');
  //最后节点的数据不需要展示
  if (finishIndex > -1 && finishIndex < 15) {
    return "finished";
  }
  const strPre = `data: {"event":`;
  const strDataPre = `data: `;
  const startIndex = str.indexOf(strPre);
  let thrunkString = "";
  let endIndex = -1;
  if (startIndex >= 0) {
    thrunkString = str.substring(startIndex + strPre.length, str.length);
    endIndex = thrunkString.indexOf(strPre);
    if (endIndex >= 0) {
      thrunkString = str.substring(
        startIndex + strDataPre.length,
        startIndex + endIndex + strPre.length
      );
    }
  }
  if (endIndex < 0) {
    return;
  }
  //校验整个thrunk是否是完整的
  let completeData;
  const tryToParse = () => {
    try {
      if (!thrunkString.endsWith("}")) {
        if (thrunkString.length > 1) {
          thrunkString = thrunkString.substring(0, thrunkString.length - 1);
          tryToParse();
        }
      } else {
        const data = JSON.parse(thrunkString);
        if (data && data.event.length > 0) {
          //解析成功
          // console.log('解析成功', data)
          completeData = {
            startIndex: startIndex,
            endIndex: startIndex + strDataPre.length + thrunkString.length,
            thrunkString: thrunkString,
            data: data
          };
        }
      }
    } catch (error) {
      if (thrunkString.length > 1) {
        thrunkString = thrunkString.substring(0, thrunkString.length - 1);
        tryToParse();
      }
    }
  };
  tryToParse();
  return completeData;
};

//把uint8Array按照最多1024个截取，最后拼成一个字符串返回
const decoderByStep = (arr: Uint8Array): string => {
  if (!(arr instanceof Uint8Array)) {
    return "";
  }

  const decoder = new TextDecoder();
  let result = "";
  for (let offset = 0; offset < arr.length; offset += 1024) {
    const chunk = arr.slice(offset, offset + 1024);
    result += decoder.decode(chunk);
  }
  return result;
};

//分割成stream样式的数组
function spliteStringToStreamArray(str: string) {
  const stream = [];
  let currentChunk = "";

  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    const code = char.charCodeAt(0);

    // 判断字符类型
    if (code >= 0x4e00 && code <= 0x9fff) {
      // 中文字符
      if (currentChunk.length > 0) {
        stream.push(currentChunk);
        currentChunk = "";
      }
      currentChunk = char;
    } else if (
      (code >= 0x0001 && code <= 0x007f) ||
      (code >= 0x00a1 && code <= 0x00ac) ||
      (code >= 0x00ae && code <= 0x01a1)
    ) {
      // 英文字符和部分特殊符号
      if (currentChunk.length > 0 && !/\p{P}/u.test(char)) {
        // 非标点符号
        stream.push(currentChunk);
        currentChunk = "";
      }
      currentChunk += char;
    } else if (/\p{P}/u.test(char)) {
      // 标点符号
      if (currentChunk.length > 0) {
        stream.push(currentChunk);
        currentChunk = "";
      }
      stream.push(char);
    } else {
      // 其他字符，如数学公式等
      if (currentChunk.length > 0) {
        stream.push(currentChunk);
        currentChunk = "";
      }
      stream.push(char);
    }
  }

  if (currentChunk.length > 0) {
    stream.push(currentChunk);
  }

  return stream;
}

export default {
  handleAgentUrl,
  handleAgentRequestHeader,
  handleAgentResult,
  handleAgentQuery,
  spliteStringToStreamArray,
  decoderByStep,
  getRandomData,
  findCompleteThrunk
};
