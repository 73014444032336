import React, { useLayoutEffect } from "react";
import * as echarts from "echarts";
// import { Image, Tooltip } from "antd";
import { AgentOutCardDataProps } from "../../common/commonData";
import { GraphColors } from "./config";
import "./index.scss";
// import copyIcon from "@/assets/icons/copy.png";

const AgentOutCardGraph: React.FC<AgentOutCardDataProps> = ({
  data
  // copyAction
}) => {
  const timestamp = Date.now(); // 获取当前的时间戳
  const randomId = Math.floor(Math.random() * timestamp).toString(); // 使用时间戳和Math.random生成随机数

  // const copy = useCallback((item: any) => {
  //   if (copyAction) {
  //     copyAction(item);
  //   }
  // }, []);

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * GraphColors.length);
    return GraphColors[randomIndex];
  };

  useLayoutEffect(() => {
    const nodes: any[] = [];
    data.nodes.forEach((e: any, idx: number) => {
      const newNode = {
        id: e.id,
        name: e.name,
        symbolSize: idx === 0 ? 30 : 20,
        itemStyle: {
          color: getRandomColor()
        }
      };
      nodes.push(newNode);
    });

    const myChart = echarts.init(document.getElementById(`graph-${randomId}`));
    myChart.setOption({
      series: [
        {
          type: "graph", // 类型:关系图
          layout: "force", // 图的布局-力引导布局
          symbolSize: 10, // 节点标记的大小
          roam: true, // 是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移,可以设置成 'scale' 或者 'move'。设置成 true 为都开启
          // 力引导布局前的初始化布局，初始化布局会影响到力引导的效果
          force: {
            repulsion: 100 // 节点之间的斥力因子,值越大斥力越大
          },
          draggable: true, // 标签是否可以允许用户通过拖拽二次调整位置
          zoom: 2, // 当前视角的缩放比例
          label: {
            show: true,
            position: "inside",
            color: "#fff",
            fontFamily: "Roboto-Regular",
            fontSize: "8px",
            align: "center",
            verticalAlign: "middle",
            formatter: (params: any) => {
              const { name } = params;
              const array = name.split(" ");
              let newLabel = "";
              array.map((item: any) => {
                return (newLabel = newLabel + item + "\n");
              });

              return newLabel;
            }
          },
          itemStyle: {
            borderColor: "#828282",
            borderWidth: 1
          },
          // 公用线条样式
          lineStyle: {
            width: 1,
            color: "#4b565b"
          },
          data: nodes,
          links: (data as any).links,
          categories: (data as any).categories
        }
      ]
    });

    // 页面缩放图表重绘
    window.addEventListener("resize", () => {
      myChart.resize();
    });

    return () => {
      myChart.dispose();
    };
  }, []);

  return (
    <div className="graph">
      <div className="graph-left">
        <div id={`graph-${randomId}`} className="graph-main"></div>
        {/* <div className="graph-left-btn">
          <Tooltip
            placement="bottom"
            arrow={false}
            title="Continue asking questions about this module."
          >
            <Image src={copyIcon} preview={false} onClick={() => copy(data)} />
          </Tooltip>
        </div> */}
      </div>
      <div className="graph-right"></div>
    </div>
  );
};

export default AgentOutCardGraph;
