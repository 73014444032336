import React, { useRef } from "react";
import { message } from "antd";
import classnames from "classnames";
import { AudioConfig } from "./config";
import "./index.scss";

const Audio = (props: AudioConfig) => {
  const { className, defaultIcon, callTransformBack, isEditable } = props;
  const [isActive, setIsActive] = React.useState(false);
  const isRecording = useRef(false);
  const mediaRecorder = useRef(null as any);
  let audioChunks: any = [];
  let audioContext, analyser, microphone;
  let silenceTimeout: any;

  // 手动触发录音/停止录音
  const getStart = () => {
    if (!isEditable) return;

    if (isRecording.current) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  // 开始录音
  const startRecording = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true
        });
        audioContext = new window.AudioContext();
        analyser = audioContext.createAnalyser();
        microphone = audioContext.createMediaStreamSource(stream);
        microphone.connect(analyser);
        analyser.fftSize = 256;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        mediaRecorder.current = new MediaRecorder(stream);
        mediaRecorder.current.ondataavailable = (event: any) => {
          audioChunks.push(event.data);
        };
        mediaRecorder.current.onstop = async () => {
          const audioBlob = new Blob(audioChunks);
          callTransformBack(audioBlob);
        };
        isRecording.current = true;
        setIsActive(true);
        mediaRecorder.current.start();
        audioChunks = [];
        // 检测静音
        detectSilence(dataArray, analyser, silenceDetected);
      } catch (error) {
        message.error("Recording failed, please try again");
      }
    } else {
      console.error(
        "The browser does not support audio recording, please enable it"
      );
    }
  };

  // 检测是否静音
  const detectSilence = (
    dataArray: any,
    analyser: any,
    callback: () => void,
    threshold = 0.01,
    timeout = 500
  ) => {
    const checkSilence = () => {
      analyser.getByteFrequencyData(dataArray);

      let sum = 0;
      for (const amplitude of dataArray) {
        sum += amplitude;
      }

      const average = sum / dataArray.length;

      if (average < threshold * 256) {
        if (!silenceTimeout) {
          silenceTimeout = setTimeout(() => {
            callback();
          }, timeout);
        }
      } else {
        clearTimeout(silenceTimeout);
        silenceTimeout = null;
      }

      if (isRecording.current) {
        requestAnimationFrame(checkSilence);
      }
    };

    requestAnimationFrame(checkSilence);
  };

  // 检测到静音，自动停止录音
  const silenceDetected = () => {
    stopRecording();
  };

  // 停止录音
  const stopRecording = () => {
    mediaRecorder.current.stop();
    isRecording.current = false;
    setIsActive(false);
  };

  return (
    <div className={classnames("audio", className)} onClick={getStart}>
      <div
        className={classnames("audio-bg-a", {
          "audio-bg-a-active2": isActive
        })}
      >
        <div
          className={classnames("audio-bg-b", {
            "audio-bg-b-active": isActive
          })}
        >
          {defaultIcon}
        </div>
      </div>
    </div>
  );
};

export default Audio;
