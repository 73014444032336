const GraphColors = [
  "#2E2F30",
  "#005E7B",
  "#9B0000",
  "#009975",
  "#02007B",
  "#457B00",
  "#9F9900",
  "#005E7B",
  "#02007B",
  "#7B0099",
  "#4996B7",
  "#B74998",
  "#BCA2D1",
  "#B78549",
  "#B88D8D",
  "#995C00",
  "#4954B7"
];

export { GraphColors };
