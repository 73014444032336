// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty {
  width: 100%;
}
.empty img {
  max-width: 0.41667rem;
}
.empty p {
  font-size: 0.0625rem;
  color: var(--var-prismer-color4) !important;
  margin-left: 0.05208rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/userProfile/components/empty/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EACE,qBAAA;AAEJ;AAAE;EACE,oBAAA;EACA,2CAAA;EACA,uBAAA;AAEJ","sourcesContent":[".empty {\n  width: 100%;\n  img {\n    max-width: 80px;\n  }\n  p {\n    font-size: 12px;\n    color: var(--var-prismer-color4) !important;\n    margin-left: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
