// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found {
  width: 90%;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.not-found-icon {
  width: 2.08333rem;
  height: 2.08333rem;
  background: #f4f5f7;
  border-radius: 0.26042rem;
  display: flex;
  justify-content: center;
  box-shadow: 0 0.02083rem 0.07813rem 0.02604rem rgba(157, 163, 183, 0.2);
  margin-top: 1.5625rem;
}
.not-found-icon .anticon {
  font-size: 0.72917rem;
  color: var(--var-prismer-color3);
}
.not-found p {
  font-size: 0.20833rem;
  margin-top: 0.15625rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/notFound/index.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AAAE;EACE,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,uEAAA;EACA,qBAAA;AAEJ;AADI;EACE,qBAAA;EACA,gCAAA;AAGN;AAAE;EACE,qBAAA;EACA,sBAAA;AAEJ","sourcesContent":[".not-found {\n  width: 90%;\n  height: 100vh;\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  &-icon {\n    width: 400px;\n    height: 400px;\n    background: #f4f5f7;\n    border-radius: 50px;\n    display: flex;\n    justify-content: center;\n    box-shadow: 0px 4px 15px 5px #9da3b733;\n    margin-top: 300px;\n    .anticon {\n      font-size: 140px;\n      color: var(--var-prismer-color3);\n    }\n  }\n  p {\n    font-size: 40px;\n    margin-top: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
