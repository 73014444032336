/*
 *接口地址
 */
const BUILD_ENV = process.env.REACT_APP_BUILD_ENV || "development";

// 接口域名
const getPrefix = (env: string) => {
  const innerPrefix = window.location.origin;
  let prefix = "";

  switch (env) {
    case "development":
      // prefix = `${innerPrefix}/mocks`;
      prefix = "http://192.168.31.41:9999";
      // prefix = "https://docbrew.cn/api";
      break;
    case "stg":
      prefix = "http://223.166.23.84:9999";
      prefix = "https://docbrew.cn/api";
      break;
    case "production":
      prefix = innerPrefix;
      prefix = "https://docbrew.cn/api";
      break;
  }

  return prefix;
};

// 接口路径
const Apis = {
  userHistory: "/user/history", // 历史数据Menu
  userChatHistoryDetails: "/user/details", //历史数据详情
  upload: "/doc/upload", // 上传文件
  uploadProcess: "/doc/process", // 上传文件进度
  chat: "/user/chat", // chat
  audioTranscriptions: "/v1/audio/transcriptions", // 音频转文字
  generationProjectCodeStatus: "/code/status", // 获取代码工程生成状态
  userUpload: "/users/upload/cv/", // 上传简历
  userInfo: "/users/", // 用户信息
  tagsList: "/tags/list", // 标签列表
  tagsRecommend: "/tags/recommendation", // 推荐标签
  userTag: "/tags/", // 用户个人标签
  puk: "/puk", // 获取公钥
  verifyPhone: "/verify_phone", // 发送手机验证码
  register: "/register", // 注册
  loginWithVerification: "/login/verification", // 手机验证方式登录
  loginWithPassword: "/login/password", // 手机密码方式登录
  loginWechat: "/login/wechat", // 微信登录
  wechatBindPhone: "/bind_phone/wechat", //绑定手机号
  bindIniviCode: "/users/invite", //绑定邀请码
  trackingEvent: "/event", //埋点
  addToChatHistory: "/user/add_history/" //添加到chat记录
};

const Api: any = Apis;
for (const item in Api) {
  Api[item] = getPrefix(BUILD_ENV) + Api[item];
}

export const Prefix = getPrefix(BUILD_ENV);
export default Api;
