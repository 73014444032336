import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import type { FormProps } from "antd";
import { Button, Upload, Input, Form, DatePicker, message, Select } from "antd";
import {
  UploadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  LinkOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import {
  SendPostRequest,
  SendPutRequest,
  Api,
  Constant,
  Store
} from "@/common/";
import "./index.scss";
import logo from "@/assets/images/sider_logo.png";
import userPicture from "@/assets/icons/user_picture.png";
import editPen from "@/assets/icons/edit_pen.png";
import pen from "@/assets/icons/pen_grey.png";
import arrow from "@/assets/icons/arrow_down_grey.png";

const { Search } = Input;

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const CreateProfile = () => {
  const EducationOptions = [
    { value: "Bachelor", label: "Bachelor" },
    { value: "Master", label: "Master" },
    { value: "PhD", label: "PhD" },
    { value: "Others", label: "Others" }
  ];
  const wechatAvatar = Store.getState().userSlice.wechatAvatar;
  const wechatName = Store.getState().userSlice.wechatName;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const userId = localStorage.getItem(Constant.LOCAL_KEY.userId);
  const [showTips, setShowTips] = useState(true);
  const [userP, setUserP] = useState(
    wechatAvatar && wechatAvatar.length > 0 ? wechatAvatar : userPicture
  );
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [isUploadingUrl, setIsUploadingUrl] = useState(false);
  const [profileData, setProfileData] = useState(null as any);
  const [allData, setAllData] = useState(null as any);
  const [hasUploaded, setHasUploaded] = useState(false);

  const goHomePage = () => {
    navigate("/");
  };

  // 上传用户头像
  const uploadUserPicture = (e: any) => {
    const file = e.target.files[0];
    const sizeInMB = file.size / 1024 / 1024;
    const reader = new FileReader();

    reader.onload = function (e: any) {
      const imgBase64String = e.target.result;

      if (sizeInMB > 5) {
        message.error("Please upload files smaller than 5MB");
      } else {
        setUserP(imgBase64String);
      }
    };
    reader.readAsDataURL(file);
    e.target.value = "";
  };

  // 上传函数
  const uploadFun = (file: any, url: string) => {
    file !== "" && setIsUploadingFile(true);
    url !== "" && setIsUploadingUrl(true);
    SendPostRequest(
      `${Api.userUpload}${userId}`,
      {
        file: file,
        url: url
      },
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ).then((res: any) => {
      const {
        data,
        status: { code, desc }
      } = res;

      if (code === Constant.HTTP_STATUS.SUCCESS) {
        const allData = JSON.parse(data.info);
        const basicInfoData = allData.basicInfo;
        const relateLinksData = allData.relatedLinks;
        const websiteFirst = relateLinksData[0];
        const websiteOthers = relateLinksData.slice(1, relateLinksData.length);
        const newData = {
          firstName: basicInfoData.name.firstName,
          lastName: basicInfoData.name.lastName,
          phoneNumber: basicInfoData.contactInfo.phoneNumber,
          email: basicInfoData.contactInfo.email,
          nickname: basicInfoData.nickname || wechatName,
          location: basicInfoData.location,
          birthday:
            basicInfoData.birthday === ""
              ? undefined
              : dayjs(basicInfoData.birthday),
          degree: basicInfoData.degree,
          occupation: basicInfoData.occupation,
          company: basicInfoData.company,
          websiteTitle: websiteFirst?.websiteTitle,
          url: websiteFirst?.url,
          websites: websiteOthers.slice(0, 4)
        };

        setAllData(allData);
        setProfileData(newData);
        form.setFieldsValue(newData);
        setShowTips(false);
        setHasUploaded(true);
      } else {
        message.error(desc);
      }
      file !== "" && setIsUploadingFile(false);
      url !== "" && setIsUploadingUrl(false);
    });
  };

  // 上传简历
  const uploadProfile = (e: any, event?: any, fromDom?: any) => {
    const urlRegex = /^https?:\/\/[^\s$.?]+\.[^\s]*$/i;
    let source: any = null;

    if (event) {
      source = fromDom.source;
    }
    if (source && source === "clear") return;
    if (e === "") {
      message.error("Please add your profile url");
      return;
    }
    if (e.file) {
      uploadFun(e.file, "");
      return;
    }
    if (!urlRegex.test(e)) {
      message.error("Please enter a valid URL");
      return;
    }
    uploadFun("", e);
  };

  const getTips = () => {
    setShowTips(false);
  };

  // json合并
  const mergeData = (json1: any, json2: any) => {
    const result = JSON.parse(JSON.stringify(json1));

    Object.assign(result.basicInfo.name, {
      firstName: json2.firstName,
      lastName: json2.lastName
    });
    Object.assign(result.basicInfo.contactInfo, {
      phoneNumber: json2.phoneNumber,
      email: json2.email
    });
    Object.assign(result.basicInfo, {
      location: json2.location,
      occupation: json2.occupation,
      nickname: json2.nickname,
      birthday: dayjs(json2.birthday).format("YYYY-MM-DD"),
      degree: json2.degree
    });
    result.relatedLinks = [
      { websiteTitle: json2.websiteTitle, url: json2.url },
      ...json2.websites
    ];

    return result;
  };

  // 数据按照后端要求格式化
  const transformData = (source: any) => {
    // 定义目标格式的模板
    const targetTemplate = {
      basicInfo: {
        name: {
          firstName: "",
          lastName: ""
        },
        occupation: "",
        location: "",
        contactInfo: {
          email: "",
          phoneNumber: "",
          officeAddress: ""
        },
        personalIntroduction: "",
        avatar: "",
        nickname: "",
        birthday: "",
        degree: "",
        company: ""
      },
      careerHistory: {
        workExperience: [],
        educationalExperience: [],
        awards: []
      },
      publications: {
        books: [],
        articles: [],
        papers: [],
        codeProjects: []
      },
      relatedLinks: [] as any
    };

    // 填充数据源到目标模板中
    Object.assign(targetTemplate.basicInfo.name, {
      firstName: source.firstName || "",
      lastName: source.lastName || ""
    });
    Object.assign(targetTemplate.basicInfo.contactInfo, {
      phoneNumber: source.phoneNumber || "",
      email: source.email || ""
    });
    Object.assign(targetTemplate.basicInfo, {
      location: source.location || "",
      occupation: source.occupation || "",
      nickname: source.nickname || "",
      birthday: source.birthday || "",
      degree: source.degree || ""
    });
    Object.assign(targetTemplate, {
      relatedLinks:
        [
          {
            websiteTitle: source.websiteTitle || "",
            url: source.url || ""
          },
          ...(source.websites || [])
        ] || []
    });

    return targetTemplate;
  };

  const setUserInfo = (pData: any, callBack: any) => {
    SendPutRequest(`${Api.userInfo}${userId}`, {
      tags: [],
      ...pData
    }).then((res: any) => {
      const {
        status: { code, desc }
      } = res;

      if (code === Constant.HTTP_STATUS.SUCCESS) {
        callBack();
      } else {
        message.error(desc);
      }
    });
  };

  // form表单数据提交
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    const data = allData ? mergeData(allData, values) : transformData(values);
    const {
      basicInfo: { nickname }
    } = data;

    const pData: any = {
      avatar: userP === userPicture ? "" : userP,
      name: nickname,
      info: JSON.stringify(data)
    };

    const url = hasUploaded ? "/createTag?hasUploaded" : `/createTag`;
    setUserInfo(pData, () => {
      navigate(url);
    });
  };

  const goSkip = () => {
    navigate("/createTag");
  };

  useEffect(() => {
    if (!wechatName || wechatName.length === 0) return;
    form.setFieldsValue({ nickname: wechatName });
  }, [wechatName]);

  return (
    <div className="create-profile">
      <div className="create-profile-part">
        <img src={logo} className="create-profile-logo" onClick={goHomePage} />
        <div className="create-profile-box">
          <div className="create-profile-back">
            <p>Step 2 of 3</p>
          </div>
          <h4 className="create-profile-title">Create your profile</h4>
          <div className="create-profile-main">
            <div className="create-profile-main-left">
              <div className="create-profile-main-left-user">
                <div className="create-profile-main-left-img-box">
                  <input
                    type="file"
                    className="create-profile-main-left-user-pic"
                    accept="image/jpeg, image/jpg, image/bmp, image/gif"
                    onChange={uploadUserPicture}
                  />
                  <div className="create-profile-main-left-picture-box">
                    <img
                      src={userP}
                      className="create-profile-main-left-picture"
                    />
                  </div>
                  <img
                    src={editPen}
                    className="create-profile-main-left-edit"
                  />
                </div>
                <p>
                  Allowed *.jpeg,*.jpg,*.bmp,*.gif
                  <br />
                  Max size of 5.00MB
                </p>
                <div className="create-profile-main-left-upload">
                  <h5>Quick Upload</h5>
                  <p>
                    You can upload information with a profile, and we will
                    quickly generate your user information content.
                  </p>
                  <Upload
                    accept=".pdf"
                    showUploadList={false}
                    customRequest={uploadProfile}
                    disabled={isUploadingFile || isUploadingUrl}
                  >
                    <Button
                      icon={
                        isUploadingFile ? (
                          <LoadingOutlined />
                        ) : (
                          <UploadOutlined />
                        )
                      }
                    >
                      Click to Upload
                    </Button>
                  </Upload>
                </div>
                <div className="create-profile-main-left-url">
                  <p>Or /Add Your Profile URL</p>
                  <Search
                    prefix={<LinkOutlined />}
                    placeholder="http://"
                    allowClear
                    disabled={isUploadingUrl || isUploadingFile}
                    enterButton={
                      isUploadingUrl ? <LoadingOutlined /> : "upload"
                    }
                    onSearch={uploadProfile}
                  />
                </div>
              </div>
            </div>
            <div className="create-profile-main-right">
              <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                autoComplete="off"
                initialValues={profileData}
              >
                <Form.Item
                  style={{
                    width: "48%",
                    display: "inline-block",
                    marginRight: "4%"
                  }}
                >
                  <Form.Item label="Full name">
                    <Form.Item
                      name="lastName"
                      style={{
                        display: "inline-block",
                        width: "48%",
                        marginRight: "4%"
                      }}
                    >
                      <Input
                        placeholder="Last name"
                        maxLength={18}
                        suffix={<img src={pen} />}
                      />
                    </Form.Item>
                    <Form.Item
                      name="firstName"
                      style={{
                        display: "inline-block",
                        width: "48%"
                      }}
                    >
                      <Input
                        placeholder="First name"
                        maxLength={18}
                        suffix={<img src={pen} />}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Prismer Nickname"
                      name="nickname"
                      rules={[
                        {
                          required: true,
                          message:
                            "You must complete this fields before proceeding to the next step."
                        }
                      ]}
                      className="nick-name-box"
                    >
                      <Input suffix={<img src={pen} />} maxLength={18} />
                    </Form.Item>
                    <Form.Item label="Date Of Birth" name="birthday">
                      <DatePicker
                        placeholder="yyyy-mm-dd"
                        format="YYYY-MM-DD"
                        suffixIcon={<img src={arrow} />}
                      />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item label="Education Degree" name="degree">
                    <Select
                      placeholder="M.M.S"
                      options={EducationOptions}
                      suffixIcon={<img src={arrow} />}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    />
                  </Form.Item>
                  <Form.Item label="Profession" name="occupation">
                    <Input maxLength={18} suffix={<img src={pen} />} />
                  </Form.Item>
                  <Form.Item label="Company" name="company">
                    <Input maxLength={18} suffix={<img src={pen} />} />
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  style={{
                    width: "48%",
                    display: "inline-block"
                  }}
                >
                  <Form.Item
                    label="Phone number"
                    name="phoneNumber"
                    style={{
                      width: "38%",
                      display: "inline-block",
                      marginRight: "4%"
                    }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Email Address"
                    name="email"
                    style={{
                      width: "58%",
                      display: "inline-block"
                    }}
                  >
                    <Input suffix={<img src={pen} />} />
                  </Form.Item>
                  <Form.Item label="Location" name="location">
                    <Input suffix={<img src={pen} />} />
                  </Form.Item>
                  <Form.Item label="Website URL" className="form-item-website">
                    <Form.Item
                      style={{
                        display: "inline-block",
                        width: "28%",
                        marginRight: "4%"
                      }}
                      name="websiteTitle"
                    >
                      <Input placeholder="Title" />
                    </Form.Item>
                    <Form.Item
                      style={{
                        display: "inline-block",
                        width: "68%"
                      }}
                      name="url"
                      rules={[
                        {
                          type: "url",
                          message: "Please enter a valid URL."
                        }
                      ]}
                    >
                      <Input placeholder="URL" />
                    </Form.Item>
                    <Form.Item className="form-item-website-add-box">
                      <Form.List name="websites">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Form.Item
                                key={key}
                                className="form-item-website-add"
                              >
                                <Form.Item
                                  {...restField}
                                  name={[name, "websiteTitle"]}
                                  style={{
                                    width: "28%",
                                    display: "inline-block",
                                    marginRight: "4%"
                                  }}
                                >
                                  <Input placeholder="Title" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "url"]}
                                  style={{
                                    width: "62%",
                                    display: "inline-block"
                                  }}
                                  rules={[
                                    {
                                      type: "url",
                                      message: "Please enter a valid URL."
                                    }
                                  ]}
                                >
                                  <Input placeholder="URL" />
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={() => remove(name)}
                                />
                              </Form.Item>
                            ))}
                            {fields.length < 4 && (
                              <Form.Item className="form-item-add-website-btn">
                                <Button
                                  onClick={() => add()}
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  Add Website URL
                                </Button>
                              </Form.Item>
                            )}
                          </>
                        )}
                      </Form.List>
                    </Form.Item>
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Button className="create-profile-skip" onClick={goSkip}>
                    Skip this step
                  </Button>
                  <Button
                    className="create-profile-continue"
                    type="primary"
                    htmlType="submit"
                  >
                    Continue
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {showTips && (
        <div className="create-profile-mask-tips">
          <div className="create-profile-mask-tips-main">
            <p>
              Upload your local profile or a link with your personal
              information. We'll quickly populate your personal information
              profile.
            </p>
            <Button className="get-tips-button" onClick={getTips}>
              Got it !
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateProfile;
