import React, { useEffect } from "react";
import { Image } from "antd";
import { AgentOutCardDataProps } from "../../common/commonData";
import "./index.scss";

const AgentOutCardFile: React.FC<AgentOutCardDataProps> = ({ data }) => {
  const [list, setList] = React.useState([]);

  useEffect(() => {
    const arr = data.files;
    setList(arr);
  }, [data]);

  const imageCard = (item: any, right: boolean) => {
    return (
      <div
        key={item.id}
        className="pm-card-document-image"
        style={{ marginRight: right ? "0" : "20px" }}
      >
        {item.cover ? <Image src={item.cover} alt="" preview={false} /> : null}
      </div>
    );
  };

  const fileCard = (item: any, right: boolean) => {
    return (
      <div
        key={item.id}
        className="pm-card-document-file"
        style={{ marginRight: right ? "0" : "20px" }}
      >
        {item.title}
      </div>
    );
  };

  return (
    <div className="pm-card-file">
      {list.map((item: any, index: number) => {
        const right = index % 3 == 2;
        if (item.type === "image") {
          return imageCard(item, right);
        } else {
          return fileCard(item, right);
        }
      })}
    </div>
  );
};

export default AgentOutCardFile;
