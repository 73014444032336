import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import {
  Assistant,
  Recommendation,
  Knowledge,
  AgentOut,
  CreateProfile,
  CreateTag,
  UserProfile,
  Register,
  Login,
  ResetPassword,
  BindMobile,
  NotFound,
  RegisterWechat
} from "../pages";

const Routes: any = [
  // 兜底路由
  { path: "*", element: <NotFound />, key: "notFound" },
  // 默认Assistant页重定向
  {
    path: "",
    element: <Navigate to="/assistant" />,
    key: "assistant"
  },
  // Assistant
  {
    path: "/assistant",
    element: <Assistant />,
    key: "assistant"
  },
  // Recommendation
  {
    path: "/recommendation",
    element: <Recommendation />,
    key: "recommendation"
  },
  // Knowledge
  {
    path: "/knowledge",
    element: <Knowledge />,
    key: "knowledge"
  },
  // agent
  {
    path: "/agentOut",
    element: <AgentOut />,
    key: "agentOut"
  }
];
const FrontEndRoutes: any = [
  // register
  {
    path: "/register",
    element: <Register />,
    key: "register"
  },
  // registerWechat
  {
    path: "/registerWechat",
    element: <RegisterWechat />,
    key: "registerWechat"
  },
  // loginWechat
  {
    path: "/loginWechat",
    element: <RegisterWechat />,
    key: "loginWechat"
  },
  //login
  {
    path: "/login",
    element: <Login />,
    key: "login"
  },
  //resetPassword
  {
    path: "/resetPassword",
    element: <ResetPassword />,
    key: "resetPassword"
  },
  //bindMobile
  {
    path: "/bindMobile",
    element: <BindMobile />,
    key: "bindMobile"
  },
  // create profile
  {
    path: "/createProfile",
    element: <CreateProfile />,
    key: "createProfile"
  },
  // create tag
  {
    path: "/createTag",
    element: <CreateTag />,
    key: "createTag"
  }
];

const OnlyShowHeaderRoutes: any = [
  // user profile
  {
    path: "/userProfile",
    element: <UserProfile />,
    key: "userProfile"
  }
];

const RouterModel = () => {
  return useRoutes(Routes);
};

const FrontEndModel = () => {
  return useRoutes(FrontEndRoutes);
};

const OnlyShowHeaderModel = () => {
  return useRoutes(OnlyShowHeaderRoutes);
};

export {
  Routes,
  RouterModel,
  FrontEndRoutes,
  FrontEndModel,
  OnlyShowHeaderRoutes,
  OnlyShowHeaderModel
};
