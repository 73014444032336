import React, { useCallback, useEffect, useState } from "react";
//markdown
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { unified } from "unified";
import remarkParse from "remark-parse";
import { visit } from "unist-util-visit";
import rehypeKatex from "rehype-katex";
import mermaid from "mermaid";
import { Image, Tooltip } from "antd";
import classnames from "classnames";
import "katex/dist/katex.min.css";
import "./index.scss";
import copyIcon from "@/assets/icons/copy.png";

interface PMMarkdownProps {
  markdown: any;
  copyAction?: (val: any) => void;
  isContinueAsk?: boolean;
}

interface CodeComponentProps {
  inline?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const PMMarkdown: React.FC<PMMarkdownProps> = ({
  markdown,
  copyAction,
  isContinueAsk
}) => {
  const { answer, id } = markdown;
  const [showCopy, setShowCopy] = useState(false);

  // 初始化Mermaid配置（可根据需要调整）
  mermaid.initialize({ startOnLoad: false });

  // 自定义Mermaid组件，用于渲染Mermaid图表
  const MermaidComponent: React.FC<{ value: string }> = ({ value }) => {
    const [svgContent, setSvgContent] = useState<any>("");

    useEffect(() => {
      // 使用 mermaidAPI.render 而不是 mermaid.render
      const renderMermaidDiagram = async () => {
        try {
          // 使用随机ID避免冲突
          const id = `mermaid-${Math.random().toString(36).substr(2, 9)}`;
          const svgCode = await mermaid.mermaidAPI.render(id, value);
          setSvgContent(svgCode);
        } catch (error) {
          console.error("Error rendering Mermaid diagram", error);
        }
      };

      renderMermaidDiagram();
    }, [value]);

    return <div dangerouslySetInnerHTML={{ __html: svgContent }} />;
  };

  // 判断mardown内的数据类型
  const detectMarkdownType = (data: string) => {
    if (!data) return;
    markdown = data.trim();

    // 检查是否为代码块
    if (markdown.startsWith("```") && markdown.includes("\n")) {
      return "code";
    }

    // 检查是否为表格
    if (markdown.startsWith("|") && markdown.includes(" | ")) {
      return "table";
    }

    // 如果不是代码块或表格，就认为是普通文本
    return "text";
  };

  // 复制操作
  const copy = useCallback((item: any) => {
    if (copyAction) {
      // 拿到复制模块的具体数据类型，用来和ai-bar交互
      const assignData = {
        id: id,
        type: detectMarkdownType(typeof item === "string" ? item : item.answer),
        continueData: answer
      };

      copyAction(assignData);
    }
  }, []);

  // 获取mardown模块内的数据是否为text类型,非text类型可一键继续提问
  const getBlockIsText = (markdownText: any) => {
    try {
      const processor = unified().use(remarkParse);
      const tree: any = processor.parse(markdownText);
      let paragraphCount = 0;
      let textContentSize = 0;
      let headingCount = 0;

      // 使用unist-util-visit遍历节点树，判断节点是否属于text类型
      visit(tree, (node) => {
        if (node.type === "paragraph") {
          paragraphCount++;
          node.children.forEach((childNode: any) => {
            if (childNode.type === "text") {
              // 这里很奇怪，markdown会把table数据解析为text类型，需进一步判断其具体的数据结构
              if (detectMarkdownType(childNode.value) === "text") {
                textContentSize += childNode.value.length;
              }
            }
          });
        } else if (node.type === "heading") {
          headingCount++;
          node.children.forEach((childNode: any) => {
            if (childNode.type === "text") {
              textContentSize += childNode.value.length;
            }
          });
        } else {
          //
        }
      });

      const isText =
        (paragraphCount > 0 || headingCount > 0) && textContentSize > 0;
      return isText;
    } catch (err) {
      console.error("Error parsing markdown", err);
      return false;
    }
  };

  const CodeComponent: React.FC<CodeComponentProps> = ({
    inline,
    className,
    children,
    ...props
  }) => {
    const matchLanguageClassnameRegexResult = /language-(\w+)/.exec(
      className || ""
    );
    if (!inline && matchLanguageClassnameRegexResult) {
      // 如果是Mermaid图表，则使用MermaidComponent进行渲染
      // if (matchLanguageClassnameRegexResult[1] === "mermaid") {
      //   return <MermaidComponent value={String(children).replace(/\n$/, "")} />;
      // }
      return (
        <SyntaxHighlighter
          language={matchLanguageClassnameRegexResult[1]}
          PreTag="div"
          {...props}
        >
          {String(children).replace(/\n$/, "")}
        </SyntaxHighlighter>
      );
    }

    return (
      <code className={className} {...props}>
        {children}
      </code>
    );
  };

  const components: any = {
    code: CodeComponent
  };

  // 非text类型展示继续提问按钮
  useEffect(() => {
    setShowCopy(!getBlockIsText(answer));
  }, [answer]);

  // 初始化Mermaid图表渲染器
  useEffect(() => {
    mermaid.initialize({ startOnLoad: true });
  }, []);

  return (
    <div
      className={classnames("pm-markdown", { "pm-markdown-block": showCopy })}
    >
      <div className="pm-markdown-box">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeKatex]}
          components={components}
        >
          {answer}
        </ReactMarkdown>
      </div>
      <div className="pm-markdown-btn">
        {showCopy && !isContinueAsk && (
          <Tooltip
            placement="bottom"
            arrow={false}
            title="Continue asking questions about this module."
          >
            <Image
              src={copyIcon}
              preview={false}
              onClick={() => copy(markdown)}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default PMMarkdown;
