import React from "react";
import { AgentOutCardDataProps } from "../../common/commonData";
import PMMarkdown from "../PMMarkdown";
import "./index.scss";

const AgentOutCardText: React.FC<AgentOutCardDataProps> = ({ data }) => {
  return (
    <div className="pm-card-text-container">
      <div className="pm-card-text" id={data.id}>
        <PMMarkdown markdown={data.value}></PMMarkdown>
      </div>
    </div>
  );
};

export default AgentOutCardText;
