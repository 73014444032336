import { configureStore, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";

import rootReducer, { RootState } from "./rootReducer";

const preloadedState: any = {};

const Store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== "production",
  preloadedState: preloadedState
  // enhancers: (getDefaultEnhancers) =>
  //   getDefaultEnhancers({
  //     autoBatch: false
  //   }),
});

// if (process.env.NODE_ENV === "development" && module.hot) {
//   module.hot.accept("./rootReducer", () => {
//     const newRootReducer = require("./rootReducer").default;
//     store.replaceReducer(newRootReducer);
//   });
// }

export type AppDispatch = typeof Store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default Store;
