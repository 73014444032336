import React from "react";
import UserAuth from "../userAuth";
import "./index.scss";

const RegisterWechat = () => {
  return (
    <div className="registerWechat">
      <UserAuth />;
    </div>
  );
};

export default RegisterWechat;
