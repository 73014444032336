import React from "react";
import { FieldNumberOutlined } from "@ant-design/icons";
import "./index.scss";

const NotFound = () => {
  return (
    <div className="not-found">
      <div className="not-found-icon">
        <FieldNumberOutlined />
      </div>
      <p>Oops, the page disappeared.</p>
    </div>
  );
};

export default NotFound;
