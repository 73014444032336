// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pm-card-code {
  width: 100%;
  min-height: 0.52083rem;
  background-color: white;
  padding: 0.05208rem;
  border-radius: 0.05208rem;
  margin-bottom: 0.05208rem;
  position: relative;
  overflow-x: scroll;
  max-width: 4.19271rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/agentOut/component/AgentOutCardASTGraph/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;AACF","sourcesContent":[".pm-card-code {\n  width: 100%;\n  min-height: 100px;\n  background-color: white;\n  padding: 10px;\n  border-radius: 10px;\n  margin-bottom: 10px;\n  position: relative;\n  overflow-x: scroll;\n  max-width: 805px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
