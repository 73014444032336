import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { AppThunk, AppDispatch } from "../appStore";
const initialState: any = {
  openid: "",
  wechatAvatar: "",
  wechatName: ""
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setOpenId(state, action: PayloadAction<string>) {
      state.openid = action.payload;
    },
    setWechatAvatar(state, action: PayloadAction<any>) {
      state.wechatAvatar = action.payload;
    },
    setWechatName(state, action: PayloadAction<any>) {
      state.wechatName = action.payload;
    },
    removeLoginUserInfo(state) {
      state.userInfo = {};
      state.openid = "";
      state.wechatAvatar = "";
      state.wechatName = "";
    }
  }
});

export const {
  setOpenId,
  setWechatName,
  setWechatAvatar,
  removeLoginUserInfo
} = userSlice.actions;

export default userSlice.reducer;
