import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  currentChatId: ""
};

const agentSlice = createSlice({
  name: "agent",
  initialState,
  reducers: {
    setCurrentChatId(state, action: PayloadAction<string>) {
      state.currentTaskId = action.payload;
    }
  }
});

export const { setCurrentChatId } = agentSlice.actions;

export default agentSlice.reducer;
