import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, Button, message } from "antd";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import classnames from "classnames";
import { SendGetRequest, Api, Constant, Store, Utils } from "@/common/";
import {
  RouterModel,
  Routes,
  FrontEndRoutes,
  FrontEndModel,
  OnlyShowHeaderRoutes,
  OnlyShowHeaderModel
} from "./routes";
import "./App.css";
import UserInfoMenu from "./components/userInfoMenu";
import siderLogo from "@/assets/images/sider_logo.png";
import assistantSelIcon from "@/assets/icons/sider_menu_assistant_sel.png";
import recommendationSelIcon from "@/assets/icons/sider_menu_recommendation_sel.png";
import knowledgeSelIcon from "@/assets/icons/sider_menu_knowledge_sel.png";
import assistantIcon from "@/assets/icons/sider_menu_assistant.png";
import knowledgeIcon from "@/assets/icons/sider_menu_knowledge.png";
import recommendationIcon from "@/assets/icons/sider_menu_recommendation.png";
import UserAvatar from "@/assets/icons/user_picture.png";
import store from "./common/appStore";
import { removeLoginUserInfo } from "./common/slices/userSlice";
import { addTrackingAction, addTrackingPage } from "./common/trackingUtil";
import { addRouteAboutLogin } from "./common/slices/commonSlice";

type MenuItem = Required<MenuProps>["items"][number];

const { Header, Sider, Content } = Layout;

const App = () => {
  const navigate = useNavigate();
  const aKey = "assistant";
  const rKey = "recommendation";
  const kKey = "knowledge";
  const [MenuItems, setMenuItems] = useState([] as MenuItem[]);
  const [selectedKey, setSelectedKey] = useState(null as any);
  const [historyItems, setHistoryItems] = useState([] as MenuItem[]);
  const [isShowFrontEndRouter, setIsShowFrontEndRouter] = useState(null as any);
  const [isOnlyShowHeaderRouter, setIsOnlyShowHeaderRouter] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const pathname = window.location.pathname;
  const userInfoRef = useRef<any>(null);
  const [showUserInfo, setShowUserInfo] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem(Constant.LOCAL_KEY.userId);
    if (
      userId &&
      userId !== "undefined" &&
      (!userInfoRef.current || userInfoRef.current.userId != userId)
    ) {
      SendGetRequest(Api.userInfo + userId, {}).then((res: any) => {
        if (res.status.code === Constant.HTTP_STATUS.SUCCESS) {
          userInfoRef.current = res.data || {};
        }
      });
    } else {
      if (userInfoRef.current && userInfoRef.current.userId == userId) {
        // console.log("----000-----");
      } else {
        userInfoRef.current = null;
      }
    }

    //如果有参数就拼上
    let routeAllPath = pathname;
    const pathList = window.location.href.split(pathname);
    if (pathList.length > 1 && pathList[1].length > 0) {
      routeAllPath = pathname + pathList[1];
    }
    Store.dispatch(addRouteAboutLogin(routeAllPath));
    handleInvitaion();
  }, [navigate]);

  const handleInvitaion = () => {
    if (window.location.href.indexOf("invitation") < 0) {
      return;
    }
    const pathList = window.location.href.split("invi_code=");
    if (pathList.length > 1 && pathList[1].length > 0) {
      localStorage.setItem(Constant.LOCAL_KEY.invitaionCode, pathList[1]);
      if (Constant.isLogin) {
        Utils.bindInviCode();
        navigate("/");
      } else {
        navigate("/register");
      }
    } else {
      navigate("/");
    }
  };

  const collapsedMenu = () => {
    setCollapsed(!collapsed);
  };

  // 点击Menu
  const onClickMenu: MenuProps["onClick"] = (e) => {
    const { key } = e;
    const sKey = new Array(key);

    if (key == aKey) {
      addTrackingPage("url_chat_open");
    }
    setSelectedKey(sKey);
    const path: any = Routes.filter((r: any) => r.key === key)[0].path || "/";
    navigate(path);
  };

  // 点击历史记录
  const onClickHistory: MenuProps["onClick"] = (e) => {
    const { key } = e;

    navigate(`/agentOut?id=${key}`);
  };

  const transformData = (data: any) => {
    return data.map((conv: any) => {
      //先这样改了
      return {
        key: conv.ConversationID,
        label: conv.Details[0] ? conv.Details[0].Message : "---"
      };
    });
  };

  // 获取用户历史记录
  const getHistoryMenu = () => {
    SendGetRequest(Api.userHistory).then((res: any) => {
      const {
        status: { code, desc },
        data
      } = res;
      const listData = [];

      if (code === Constant.HTTP_STATUS.SUCCESS) {
        for (const key in data) {
          const curData = data[key];
          if (curData.length > 0) {
            listData.push({
              key,
              label: key,
              children: transformData(curData)
            });
          }
        }
        setHistoryItems(listData);
      } else {
        message.error(desc);
      }
    });
  };

  // 设置Menu items
  useEffect(() => {
    if (!selectedKey) return;

    const key = selectedKey[0];
    setMenuItems([
      {
        key: aKey,
        label: "AI Assistant",
        icon: <img src={key === aKey ? assistantSelIcon : assistantIcon} />
      },
      {
        key: rKey,
        label: "AI Recommendation",
        icon: (
          <img
            src={key === rKey ? recommendationSelIcon : recommendationIcon}
          />
        )
      },
      {
        key: kKey,
        label: "Knowledge Hub",
        icon: <img src={key === kKey ? knowledgeSelIcon : knowledgeIcon} />
      }
    ]);
  }, [selectedKey]);

  useEffect(() => {
    const name = pathname === "/" ? `/${aKey}` : pathname;
    const navKey1 = FrontEndRoutes.find((k: any) => name.includes(k.key));
    const navKey2 = OnlyShowHeaderRoutes.find((k: any) => name.includes(k.key));

    setIsShowFrontEndRouter(navKey1 ? true : false);
    setIsOnlyShowHeaderRouter(navKey2 ? true : false);
    !navKey1 && !navKey2 && getHistoryMenu();
  }, [pathname]);

  // 刷新页面根据pathname设置路由跳转和Menu selectedKeys
  useEffect(() => {
    const pathname = window.location.pathname;
    const key = new Array(
      Routes.filter((r: any) => r.path === pathname)[0]?.key || aKey
    );

    setSelectedKey(key ? key : []);

    //如果有参数就拼上
    const pathList = window.location.href.split(pathname);
    if (pathList.length > 1 && pathList[1].length > 0) {
      navigate(pathname + pathList[1]);
    } else {
      navigate(pathname);
    }
  }, []);

  const onClickLogin = useCallback(() => {
    addTrackingAction("navbar_login_click");
    navigate("/login");
  }, []);

  const onClickSignUp = useCallback(() => {
    addTrackingAction("navbar_signup_click");
    navigate("/register");
  }, []);

  const clickAvatar = useCallback(() => {
    setShowUserInfo(true);
  }, []);

  const onClickUserMenuItem = (value: string) => {
    setShowUserInfo(false);
    if (value == Constant.USER_MENU.UserProfile) {
      navigate("/userProfile");
    }
  };

  const onClickLogout = () => {
    localStorage.removeItem(Constant.LOCAL_KEY.token);
    localStorage.removeItem(Constant.LOCAL_KEY.userId);
    store.dispatch(removeLoginUserInfo());
    userInfoRef.current = null;
    navigate("/");
    setShowUserInfo(false);
  };

  const goHomePage = () => {
    isOnlyShowHeaderRouter && navigate("/");
  };

  const appHeader = () => {
    return (
      <Header className="app-header">
        <img className="app-logo" src={siderLogo} onClick={goHomePage} />
        {userInfoRef.current ? (
          <div className="app-user-avatar" onClick={clickAvatar}>
            {userInfoRef.current.avatar ? (
              <img
                src={userInfoRef.current.avatar}
                width={40}
                height={40}
              ></img>
            ) : (
              <img src={UserAvatar} width={40} height={40}></img>
            )}
          </div>
        ) : (
          <div>
            <Button onClick={onClickLogin} className="login">
              Login
            </Button>
            <Button type="primary" onClick={onClickSignUp} className="signUp">
              Sign Up
            </Button>
          </div>
        )}
      </Header>
    );
  };

  return (
    <div className="app">
      {isShowFrontEndRouter ? (
        // 注册登录等前置路由页面
        <FrontEndModel />
      ) : (
        <Layout className="app-layout">
          {appHeader()}
          <Layout
            className={classnames({ "overflow-box": isOnlyShowHeaderRouter })}
          >
            {isOnlyShowHeaderRouter ? (
              // 用户信息等只展示公用header页面
              <OnlyShowHeaderModel />
            ) : (
              // 主页面
              <>
                <Sider
                  className={classnames("app-sider", {
                    "app-sider-collapsed": collapsed
                  })}
                >
                  {/* Menu */}
                  {selectedKey && (
                    <Menu
                      className="app-menu"
                      defaultSelectedKeys={selectedKey}
                      selectedKeys={selectedKey}
                      mode="inline"
                      items={MenuItems}
                      onClick={onClickMenu}
                    />
                  )}
                  <div className="app-line"></div>
                  {/* History */}
                  {historyItems.length > 0 && (
                    <div className="app-history">
                      <div className="app-history-title">
                        <span>History</span>
                      </div>
                      <div className="app-history-menu-box">
                        <Menu
                          mode="inline"
                          className="app-history-menu"
                          items={historyItems}
                          onClick={onClickHistory}
                        />
                      </div>
                    </div>
                  )}
                </Sider>
                <Content
                  className={classnames("app-content", {
                    "app-content-full": selectedKey && selectedKey[0] === "",
                    "app-content-open": collapsed
                  })}
                >
                  <RouterModel />
                </Content>
                <div
                  className={classnames("app-sider-icon", {
                    "app-sider-collapsed-icon": collapsed
                  })}
                  onClick={collapsedMenu}
                >
                  {collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
                </div>
              </>
            )}
          </Layout>
        </Layout>
      )}
      {showUserInfo ? (
        <UserInfoMenu
          userInfo={userInfoRef.current}
          onClickItem={onClickUserMenuItem}
          onClickOverlay={() => {
            setShowUserInfo(false);
          }}
          onLogout={onClickLogout}
        ></UserInfoMenu>
      ) : null}
    </div>
  );
};

export default App;
