import React, { useEffect } from "react";
import { Tabs, message } from "antd";
import type { TabsProps } from "antd";
import { SendGetRequest, Api, Constant } from "@/common/";
import RelatedIcons from "./config";
import { Empty } from "./components";
import "./index.scss";
import userPicture from "@/assets/icons/user_picture.png";
import location from "@/assets/icons/user_location.png";

const UserProfile = () => {
  const defaultKey = "basic";
  const [tabKey, setTabKey] = React.useState(defaultKey);
  const [userInfo, setUserInfo] = React.useState<any>({});
  const [infoData, setInfoData] = React.useState<any>(null);
  const userId = localStorage.getItem(Constant.LOCAL_KEY.userId);

  const onChange = (key: string) => {
    setTabKey(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "basic",
      label: "Basic Informations"
    },
    {
      key: "portfolio",
      label: "Portfolio"
    }
  ];

  const getPortfolioItems = (data: any) => {
    return (
      <div className="portfolio-content-box">
        {data.map((b: any, idx: number) => {
          const { cover, title, url, author, publicationDate, abstract } = b;

          return (
            <div className="portfolio-content-item" key={idx}>
              {cover && cover !== "" && <img src={cover} />}
              <div className="portfolio-content-item-main">
                {title && title !== "" && <h5>{title}</h5>}
                {author && author !== "" && (
                  <span className="author">{author}</span>
                )}
                {publicationDate && publicationDate !== "" && (
                  <span className="published">Published:{publicationDate}</span>
                )}
                {url && url !== "" && (
                  <a
                    href={url}
                    className="url"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {url}
                  </a>
                )}
                {abstract && abstract !== "" && <p>{abstract}</p>}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  // 获取用户数据
  const getUserData = () => {
    SendGetRequest(`${Api.userInfo}${userId}`).then((res: any) => {
      const {
        data,
        status: { code, desc }
      } = res;

      if (code === Constant.HTTP_STATUS.SUCCESS) {
        const info: any = data.info !== "" ? JSON.parse(data.info) : null;

        setUserInfo(data);
        setInfoData(info);
        console.log(2222, info);
      } else {
        message.error(desc);
      }
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      <div className="user-profile">
        <div className="user-profile-desc">
          <img
            src={!userInfo.avatar ? userPicture : userInfo.avatar}
            className="user-profile-desc-user"
          />
          <div className="user-profile-desc-info">
            <div className="user-profile-desc-info-nickname">
              {userInfo.name}
            </div>
            {infoData && (
              <div className="user-profile-desc-info-name">
                {infoData.basicInfo.name.lastName},
                {infoData.basicInfo.name.firstName}
              </div>
            )}
            {infoData && (
              <div className="user-profile-desc-info-profession">
                {infoData.basicInfo.occupation}
              </div>
            )}
            {infoData && (
              <div className="user-profile-desc-info-location">
                <img src={location} />
                {infoData.basicInfo.location}
              </div>
            )}
          </div>
        </div>
        <div className="user-profile-main">
          <Tabs
            defaultActiveKey={defaultKey}
            items={items}
            onChange={onChange}
          />
          {tabKey === defaultKey ? (
            <div className="basic-info">
              <div className="basic-info-left">
                <div className="basic-info-left-item basic-info-left-item-contact">
                  <div className="basic-info-left-item-title item-title">
                    <h5 className="title-name">Contact</h5>
                  </div>
                  {infoData &&
                  (infoData.basicInfo.contactInfo.officeAddress ||
                    infoData.basicInfo.contactInfo.phoneNumber ||
                    infoData.basicInfo.contactInfo.email) ? (
                    <ul>
                      <li>{infoData.basicInfo.contactInfo.officeAddress}</li>
                      <li>{infoData.basicInfo.contactInfo.phoneNumber}</li>
                      <li>{infoData.basicInfo.contactInfo.email}</li>
                    </ul>
                  ) : (
                    <Empty />
                  )}
                </div>
                <div className="basic-info-left-item basic-info-left-item-edu">
                  <div className="basic-info-left-item-title item-title">
                    <h5 className="title-name">Education</h5>
                  </div>
                  {infoData &&
                  infoData.careerHistory.educationalExperience.length > 0 ? (
                    <ul className="basic-info-left-item-edu-box">
                      {infoData.careerHistory.educationalExperience.map(
                        (edu: any, idx: number) => {
                          const { university, educationTitle, graduationDate } =
                            edu;

                          return (
                            <li key={idx}>
                              {educationTitle}
                              <p>
                                {university} {graduationDate}
                              </p>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  ) : (
                    <Empty />
                  )}
                </div>
                <div className="basic-info-left-item basic-info-left-item-awards">
                  <div className="basic-info-left-item-title item-title">
                    <h5 className="title-name">Awards</h5>
                  </div>
                  {infoData && infoData.careerHistory.awards.length > 0 ? (
                    <ul className="basic-info-left-item-awards-box">
                      {infoData.careerHistory.awards.map(
                        (awa: any, idx: number) => {
                          const { awardTitle, awardDate } = awa;

                          return (
                            <li key={idx}>
                              {awardTitle}
                              <p>{awardDate}</p>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  ) : (
                    <Empty />
                  )}
                </div>
                <div className="basic-info-left-item basic-info-left-item-related">
                  {infoData &&
                    infoData.relatedLinks.length > 0 &&
                    infoData.relatedLinks.map((r: any, idx: number) => {
                      const { website, url } = r;
                      const icon = RelatedIcons.find(
                        (ri: any) => ri.website === website
                      )?.icon;

                      return (
                        <div key={idx}>
                          <a href={url} target="_blank" rel="noreferrer">
                            {icon}
                          </a>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="basic-info-right">
                <div className="basic-info-right-item basic-info-right-item-myself">
                  <div className="basic-info-right-item-title item-title">
                    <h5 className="title-name">My Self</h5>
                  </div>
                  {infoData &&
                  infoData.basicInfo.personalIntroduction.length > 0 ? (
                    <div className="basic-info-right-item-self">
                      {infoData.basicInfo.personalIntroduction}
                    </div>
                  ) : (
                    <Empty />
                  )}
                </div>
                <div className="basic-info-right-item basic-info-right-item-experience">
                  <div className="basic-info-right-item-title item-title">
                    <h5 className="title-name">Experience</h5>
                  </div>
                  {infoData &&
                  infoData.careerHistory.workExperience.length > 0 ? (
                    <ul className="basic-info-right-item-experience">
                      {infoData.careerHistory.workExperience.map(
                        (item: any, idx: number) => {
                          const {
                            companyTitle,
                            position,
                            startDate,
                            endDate,
                            description
                          } = item;

                          return (
                            <li key={idx}>
                              <h5>{companyTitle}</h5>
                              <p>
                                <span>{position}</span>
                                <span>
                                  {startDate} - {endDate}
                                </span>
                              </p>
                              <p>{description}</p>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  ) : (
                    <Empty />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="portfolio">
              {infoData &&
              Object.keys(infoData.publications).length > 0 &&
              Object.values(infoData.publications).some(
                (item) => Array.isArray(item) && item.length > 0
              ) ? (
                <>
                  {Object.keys(infoData.publications).map((key: string) => {
                    const value = infoData.publications[key];
                    if (value.length === 0) return;

                    return (
                      <div className="portfolio-content" key={key}>
                        <h5 className="title-name">
                          {`${key.charAt(0).toUpperCase()}${key.slice(1)}`}
                        </h5>
                        {getPortfolioItems(value)}
                      </div>
                    );
                  })}
                </>
              ) : (
                <Empty />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserProfile;
