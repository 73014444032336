import React from "react";
import UserAuth from "../userAuth";
import "./index.scss";

const BindMobile = () => {
  return (
    <div className="bindMobile">
      <UserAuth />;
    </div>
  );
};

export default BindMobile;
