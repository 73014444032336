/*
 *公用常量
 */
const Constant = {
  HTTP_STATUS: {
    STATUS_SUCCESS: 200,
    SUCCESS: 0,
    UN_AUTHORIZED: 401,
    LOGIN_EXPIRED: 402,
    NO_DATA: 301,
    BIND_PHONE: 1001
  },

  //个人信息menu
  USER_MENU: {
    UserProfile: "User Profile",
    Setting: "Setting"
  },

  //本地key
  LOCAL_KEY: {
    token: "token",
    userId: "userId",
    invitaionCode: "invitaionCode"
  },
  TASK_FINISH_VALUE: 1,
  isLogin: localStorage.getItem("token") ? true : false,

  //agent
  AGENT: {
    projectUrl: "http://3.93.31.83:8443/?folder=/workspace"
  }
};

export default Constant;
