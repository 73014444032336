import React, { useCallback, useEffect, useRef, useState, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Api,
  Constant,
  SendGetRequest,
  Utils,
  Store,
  SendDeleteRequest
} from "@/common";
import { AiBar } from "@/components/";
import { Image, message, Spin, Tooltip } from "antd";
import {
  PlusCircleOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import {
  AgentOutChatContainer,
  AgentOutContainer,
  PMMarkdown,
  AgentOutCardDocument,
  AgentOutCardGraph
} from "./component/";
import agentRequestUtil from "./common/agentRequestUtil";
import arrow_left from "@/assets/icons/arrow_left.png";
import "./index.scss";
import { addTask, setStopTaskId } from "@/common/slices/taskSlice";
import { addTrackingAction } from "@/common/trackingUtil";

// agent event
const AgentEventStatus = "status";
const AgentEventError = "error";
const AgentEventFinished = "finished";
const AgentEventIntention = "intention";
// agent type
const AgentTypeDoc = ["meta", "chunk", "sentence", "exa"];
const AgentTypeMeta = "meta";
const AgentTypeChunk = "chunk";
const AgentTypeExa = "exa";
const AgentTypeMarkdown = "markdown";
const AgentTypeGraph = "graph";
const AgentTypeSplit = "split";
const AgentTypeJob = "job";
const AgentTypeManager = "manager";

const CardTypeProject = "generation code";

const AgentOut = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  let recycleTimes = 0;
  const retryMaxTimes = 1000;
  let lastHeight = 0;
  const [historyList, setHistoryList] = useState<any[]>([]);
  const [dataList, setDataList] = useState<any[]>([]);
  const currentNodeTypeRef = useRef("");
  const currentNodeTitleRef = useRef("");
  const currentEventRef = useRef("");
  const currentChatTaskIdRef = useRef("");
  const [conversationId, setConversationId] = useState("");
  const copyDataListRef = useRef<any>([]);
  const [copyData, setCopyData] = useState<any>({});
  const agentResultQueue = useRef<any[]>([]);
  const genneratingId = useRef("");
  const buffer = useRef("");
  const containerRef = useRef(null as any);
  const [editable, setEditable] = useState(true); // 是否可以输入
  const [refreshFlag, setRefreshFlag] = useState(0); // 是否要刷新
  const streamDataListRef = useRef<any[]>([]); // 当前正在输出的dataList
  const currentThrunkIdRef = useRef(""); // 正在更新的id
  const markdownTextRef = useRef<any>({}); // markdown文本类ref
  let subTextIndex = 0;
  const mapDataRef = useRef<any>([]); // document类ref
  const metaAndThunkDataRef = useRef<any>([]);
  const exaDataRef = useRef<any>([]);
  const askCountRef = useRef(0);

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getUserInputInfo();
  }, []);

  useEffect(() => {
    const id = Utils.getQueryParam("id");
    getHistoryDetail(id);
    addHistory();
  }, [location]);

  // 把未登录的chatId保存到登录用户
  const addHistory = () => {
    const unSaveChatId = localStorage.getItem("unSaveChatId");
    if (
      !unSaveChatId ||
      unSaveChatId.length == 0 ||
      unSaveChatId == "undefined"
    ) {
      return;
    }
    SendGetRequest(Api.addToChatHistory + unSaveChatId, {}).then((res: any) => {
      const { status, data } = res;
      if (status.code === Constant.HTTP_STATUS.SUCCESS) {
        localStorage.removeItem("unSaveChatId");
      } else {
        console.error(`${Api.userHistory} error: ${data.message}`);
      }
    });
  };

  // 获取用户历史记录
  const getHistoryDetail = (id: any) => {
    if ((id || "").length == 0) {
      return;
    }
    SendGetRequest(Api.userChatHistoryDetails, { id: id }).then((res: any) => {
      const { status, data } = res;
      if (status.code === Constant.HTTP_STATUS.SUCCESS) {
        const historyArr: any[] = [];
        if (data && data.length > 0) {
          setConversationId(data[0].ConversationID);
        }
        data.forEach((e: any) => {
          if (e.Sender == "user") {
            const da = {
              id: e.ID,
              role: "user",
              data: [
                {
                  id: agentRequestUtil.getRandomData(),
                  type: "text",
                  text: e.Message,
                  value: e.Message
                }
              ]
            };
            historyArr.push(da);
          } else {
            const da = {
              id: e.ID,
              role: "assitant",
              data: [
                {
                  id: agentRequestUtil.getRandomData(),
                  type: "text",
                  text: e.Message,
                  value: e.Message
                }
              ]
            };
            historyArr.push(da);
          }
        });
        setHistoryList(historyArr);
      } else {
        console.error(`${Api.userHistory} error: ${data.message}`);
      }
    });
  };

  // 将ai-bar中图片的src设置为“”已减轻后端解析压力
  const dealAiBarData = (value: any) => {
    return value.replace(/(<img[^>]*\ssrc=)"[^"]*"/g, '$1""');
  };

  const getUserInputInfo = () => {
    const res = Utils.getLocalStorage("aiBarContent");

    if (res && res.length > 0) {
      sendUserInputData(res);

      requestData(getRequestData(dealAiBarData(res)));
      Utils.removeLocalStorage("aiBarContent");
    }
  };

  /*---将编辑器数据整合以json格式整合---
   *codes:代码块
   *imgs:图片
   *docs:文件
   *links:链接
   *texts:文字内容
   */
  const parseHTMLString = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const result: {
      links: string[];
      imgs: string[];
      docs: number[];
      codes: string[];
      text: string;
    } = {
      links: [],
      imgs: [],
      docs: [],
      codes: [],
      text: ""
    };

    // 解析 <a> 标签中的 href 属性
    const links = doc.querySelectorAll<HTMLAnchorElement>("a[href]");
    links.forEach((link) => {
      result.links.push(link.getAttribute("href")!);
      link.remove();
    });

    // 解析带有 data-id 属性的 <img> 标签
    const imgs = doc.querySelectorAll<HTMLImageElement>("img[data-id]");
    imgs.forEach((img) => {
      result.imgs.push(img.getAttribute("data-id")!);
      img.remove();
    });

    // 解析带有 data-id 属性的 <em> 标签
    const docs = doc.querySelectorAll<HTMLImageElement>("em[data-id]");
    docs.forEach((doc) => {
      result.docs.push(Number(doc.getAttribute("data-id")!));
      doc.remove();
    });

    // 解析 <code> 标签的内容
    const codes = doc.querySelectorAll<HTMLElement>("code");
    codes.forEach((code) => {
      result.codes.push(code.textContent || "");
      code.remove();
    });

    // 提取所有节点的文本内容，但不包括上面已经处理的标签
    doc.body.childNodes.forEach((node) => {
      if (
        !(node instanceof HTMLElement) ||
        !node.matches("a, img[data-id], code")
      ) {
        result.text += node.textContent?.trim() + " ";
      }
    });
    result.text = result.text.trim();

    return result;
  };

  /*---整合所有数据传给后端---
   *continue_ask_data:继续提问内容
   */
  const getRequestData = (value: any) => {
    const continuAskData: any = [];
    copyDataListRef.current.map((c: any) => {
      continuAskData.push({
        title: c.title,
        content: c.content
      });
    });
    const rData = Object.assign(parseHTMLString(value), {
      continue_ask_data: continuAskData
    });

    return rData;
  };

  const editorCallBack = (value: any) => {
    if (value && value.length > 0) {
      const newValue = dealAiBarData(value);

      sendUserInputData(newValue);
      requestData(getRequestData(newValue));
    }
  };

  // 用户发送消息
  const sendUserInputData = async (input: string) => {
    const currentData =
      copyDataListRef.current.length > 0
        ? [
            ...copyDataListRef.current,
            {
              type: "div",
              data: input
            }
          ]
        : [
            {
              type: "div",
              data: input
            }
          ];
    const list = [
      {
        id: agentRequestUtil.getRandomData(),
        role: "user",
        data: currentData
      }
    ];
    setDataList((pre) => [...pre, ...list]);
    scrollContainerTop();
  };

  // 重置数据
  const resetAllRequestData = () => {
    Store.dispatch(setStopTaskId(""));
    agentResultQueue.current = [];
    mapDataRef.current = {};
    markdownTextRef.current = {};
    buffer.current = "";
    metaAndThunkDataRef.current = [];
    exaDataRef.current = [];
    copyDataListRef.current = [];
  };

  // 请求agent数据
  const requestData = async (inputContent: any) => {
    if ((inputContent || "").length == 0) {
      return;
    }

    //去登录
    if (
      process.env.REACT_APP_BUILD_ENV !== "development" &&
      askCountRef.current >= 1 &&
      !Constant.isLogin
    ) {
      navigate("/login");
      return;
    }
    askCountRef.current++;

    resetAllRequestData();

    // 先更新站位图
    updateStreamListPlaceholder();

    // 开始请求
    const url = agentRequestUtil.handleAgentUrl();
    const queryData = agentRequestUtil.handleAgentQuery(
      inputContent,
      conversationId
    );
    await fetch(url, {
      method: "POST",
      headers: agentRequestUtil.handleAgentRequestHeader() as any,
      body: JSON.stringify(queryData)
    })
      .then((response) => {
        if (!response || !response.ok || !response.body) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.body;
      })
      .then((stream) => {
        setEditable(false);
        handleStreamData(stream);
        pollingOutputAgentPool();
        addTrackingAction("chat_send_click");
      })
      .catch((e) => {
        console.error(e);
      });
  };

  // 处理流式数据
  const handleStreamData = (stream: any) => {
    const reader = stream.getReader();
    const readStream = () => {
      reader.read().then(({ done, value }: { done: boolean; value: any }) => {
        if (
          Store.getState().taskSlice.stopTaskId &&
          Store.getState().taskSlice.stopTaskId.length > 0
        ) {
          // console.log("%c点了停止，不再装载内容====", "color: red");
        } else {
          agentResultQueue.current = [...agentResultQueue.current, value];
          !done && readStream();
        }
      });
    };

    readStream();
  };

  // 轮询从agentResultQueue读取数据，显示到界面上。
  const pollingOutputAgentPool = useCallback(() => {
    if ((Store.getState().taskSlice.stopTaskId || "").length > 0) {
      // console.log("%c停止轮询，用户主动点击结束========", "color: red");
      stopAgentOut();
      return;
    }

    if (agentResultQueue.current.length > 0 || buffer.current.length > 0) {
      const res = agentResultQueue.current.shift();
      const decodeValue = agentRequestUtil.decoderByStep(res);

      // 拼接到buffer上
      buffer.current = buffer.current + decodeValue;
      const thrunkJsonData: any = agentRequestUtil.findCompleteThrunk(
        buffer.current
      );

      if (thrunkJsonData == AgentEventFinished) {
        genneratingId.current = "";
        removeStreamListPlaceholder();
        stopAgentOut();
        onStopGenerating();
        return;
      }
      if (
        thrunkJsonData &&
        thrunkJsonData.startIndex > -1 &&
        thrunkJsonData.endIndex > -1
      ) {
        // console.log('%c找到完整的thrunk====', 'color: red', chrunk);
        buffer.current = buffer.current.slice(thrunkJsonData.endIndex);
      }
      //处理结果数据
      setTimeout(pollingOutputAgentPool, 50);
      handleThrunkData(thrunkJsonData);
    } else {
      //服务器如果一直停止输出，延时3s之后就停止接收
      if (recycleTimes >= retryMaxTimes) {
        console.log("%c轮询次数到最大次数，停止========", "color: red");
        stopAgentOut();
        return;
      }
      recycleTimes++;
      setTimeout(pollingOutputAgentPool, 50); //轮询
    }
  }, [agentResultQueue.current]);

  // 处理agent返回的thrunk数据
  const handleThrunkData = (thrunkData: any) => {
    if (!thrunkData) return;

    const random = new Date().getTime() + Math.floor(Math.random() * 1000);
    const randomId = random.toString();
    const { data, task_id } = thrunkData;
    const { event, type, answer, conversation_id } = data;
    currentChatTaskIdRef.current = task_id;
    setConversationId(conversation_id);

    if (!Constant.isLogin) {
      localStorage.setItem("unSaveChatId", conversation_id);
    }

    // 给answer赋予id和type属性，用于继续提问
    if (answer) {
      Object.assign(answer, {
        id: randomId,
        type
      });
    }

    /*
     * --thrunk数据中event类型--
     * status:状态，用以展示在agent回答内容的最上方
     * error:thrunk数据流中包含错误信息，页面中提示此错误
     * finished:thrunk数据流返回结束
     */
    if (event === AgentEventStatus) {
      currentEventRef.current = answer;
      updateStreamListPlaceholder();
    } else if (event === AgentEventError) {
      data.type = AgentEventError;
      streamDataListRef.current = [...streamDataListRef.current, ...[data]];
      updateStreamListData();
    } else if (
      event === AgentEventIntention &&
      answer == "code" &&
      AgentTypeManager == type
    ) {
      console.log("意图分析生成code===", data.task_id);
      addToPoolingTaskPool(data.conversation_id, data.task_id);
    }

    const commonUpdateFun = () => {
      streamDataListRef.current = [...streamDataListRef.current, ...[data]];
      updateStreamListData();
    };

    /*
     * --thrunk数据中type类型--
     * meta/chunk/exa/sentence:均属于文档类型，以卡片的形式展示在回答内容中，其中meta和chunk一共最多展示3个，exa最多展示6个
     * graph:知识图谱
     * finished:thrunk数据流返回结束
     * markdown:均使用markdown组件展示具体信息，但不同的内容类型分块展示
     * split: markdown数据流中不同内容类型的分割线
     */
    if (AgentTypeDoc.includes(type)) {
      if (type === AgentTypeMeta || type === AgentTypeChunk) {
        if (metaAndThunkDataRef.current.length > 2) return;
        metaAndThunkDataRef.current.push(data);
      } else if (type === AgentTypeExa) {
        if (exaDataRef.current.length > 5) return;
        exaDataRef.current.push(data);
      } else {
        // 暂无type=sentence的数据
      }
      commonUpdateFun();
    } else if (type === AgentTypeGraph) {
      commonUpdateFun();
    } else if (type === AgentTypeMarkdown || type === AgentTypeManager) {
      const { des = "" } = markdownTextRef.current;

      markdownTextRef.current = {
        subType: `${AgentTypeMarkdown}_${subTextIndex}`,
        des: des + answer
      };
      updateStreamListMarkdown();
    } else if (type === AgentTypeSplit) {
      ++subTextIndex;
      markdownTextRef.current = {
        subType: `${AgentTypeMarkdown}_${subTextIndex}`,
        des: ""
      };
    } else if (type === AgentTypeJob) {
      const { content, n } = answer;
      updateStreamListJob(n, content);
    } else {
      //
    }
  };

  //更新streamList
  const updateStreamListPlaceholder = () => {
    removeStreamListPlaceholder();
    const arr = [
      {
        id: currentThrunkIdRef.current + agentRequestUtil.getRandomData(),
        type: "placeholder",
        text: "加载中...",
        nodeType: currentNodeTypeRef.current,
        chrunkTitle: currentNodeTitleRef.current,
        thrunkEvent: currentEventRef.current
      }
    ];
    streamDataListRef.current = [...streamDataListRef.current, ...arr];
    // scrollContainerTop();
    setRefreshFlag((pre) => pre + 1);
  };

  // 移除streamList中的上一个placeholder
  const removeStreamListPlaceholder = () => {
    const index = streamDataListRef.current.findIndex(
      (e: any) => e.type == "placeholder"
    );
    if (index > -1) {
      streamDataListRef.current.splice(index, 1);
    }
  };

  // 更新streamList中makdown text数据
  const updateStreamListMarkdown = () => {
    const itemExists = streamDataListRef.current.some(
      (item: any) => item.subType === `${AgentTypeMarkdown}_${subTextIndex}`
    );

    removeStreamListPlaceholder();
    if (itemExists) {
      streamDataListRef.current.forEach((i: any) => {
        if (i.subType === `${AgentTypeMarkdown}_${subTextIndex}`) {
          i.answer = markdownTextRef.current.des;
        }
      });
    } else {
      streamDataListRef.current = [
        ...streamDataListRef.current,
        {
          type: AgentTypeMarkdown,
          id: agentRequestUtil.getRandomData(),
          ...markdownTextRef.current
        }
      ];
    }
    setRefreshFlag((pre) => pre + 1);
  };

  // codeAgent job
  const updateStreamListJob = (n: number, content: string) => {
    const itemExists = streamDataListRef.current.some(
      (item: any) => item.subType === `${AgentTypeJob}_${n}`
    );

    removeStreamListPlaceholder();
    if (itemExists) {
      streamDataListRef.current.forEach((i: any) => {
        const { subType, answer } = i;

        if (subType === `${AgentTypeJob}_${n}`) {
          i.answer = answer + content;
        }
      });
    } else {
      streamDataListRef.current = [
        ...streamDataListRef.current,
        {
          id: agentRequestUtil.getRandomData(),
          type: AgentTypeMarkdown,
          subType: `${AgentTypeJob}_${n}`,
          answer: content
        }
      ];
    }

    setRefreshFlag((pre) => pre + 1);
  };

  // 更新streamList
  const updateStreamListData = () => {
    removeStreamListPlaceholder();
    setRefreshFlag((pre) => pre + 1);
  };

  function scrollContainerTop() {
    // 暂时不加自动滚动
    const element: any = document.getElementById("my-app");
    const height = element.offsetHeight;
    const scrollHeight = element.scrollHeight;
    if (lastHeight < height) {
      lastHeight = height;
    }
    if (scrollHeight > lastHeight) {
      containerRef.current.scrollTop += scrollHeight - lastHeight;
    }
  }

  // 重新生成
  const onReGenerating = (data: any) => {
    if (streamDataListRef.current.length > 0) {
      return;
    }
    const index = dataList.findIndex((item: any) => item.id == data.id);
    if (index > -1 && dataList.length > index - 1) {
      const miniData = dataList[index - 1];
      const inputData = (miniData.data as any[]).map((e) => e.data).join("");
      sendUserInputData(inputData);
      requestData(getRequestData(inputData));
    }
    //历史数据里上一次输入的内容
    const indexHistory = historyList.findIndex(
      (item: any) => item.id == data.id
    );
    if (indexHistory > -1 && historyList.length > indexHistory - 1) {
      const miniData = historyList[indexHistory - 1];
      if (miniData.role == "user") {
        const inputData = (miniData.data as any[]).map((e) => e.text).join("");
        sendUserInputData(inputData);
        requestData(getRequestData(inputData));
      }
    }
  };

  // 停止生成
  const onStopGenerating = () => {
    Store.dispatch(setStopTaskId(currentChatTaskIdRef.current));
  };

  //停止chat接口api
  const stopAgentApi = () => {
    const taskId = currentChatTaskIdRef.current;
    if (!taskId) return;
    SendDeleteRequest(Api.chat + "/" + taskId, {}).then((res: any) => {
      const { status, data } = res;
      if (status.code === Constant.HTTP_STATUS.SUCCESS) {
        console.log("停止成功");
        stopAgentOut();
      } else {
        console.error(`${Api.userHistory} error: ${data.message}`);
      }
    });
  };

  // 停止接收、显示数据
  const stopAgentOut = () => {
    removeStreamListPlaceholder();
    if (streamDataListRef.current.length == 0) {
      setEditable(true);
      setRefreshFlag((pre) => pre + 1);
      return;
    }
    agentResultQueue.current = [];
    // console.log("%c全部停止====", "color: red", streamDataListRef.current);
    const list: any[] = [];
    streamDataListRef.current.forEach((item: any) => {
      const data = {
        text: item.text,
        markdownValue: item.markdownValue,
        language: item.language,
        ...item
      };
      list.push(data);
    });
    const data = {
      id: agentRequestUtil.getRandomData(),
      role: "assitant",
      data: list
    };
    setDataList((pre) => [...pre, data]);
    streamDataListRef.current = [];
    setRefreshFlag((pre) => pre + 1);
    setEditable(true);
  };

  const getHistorySubContainer = (data: any) => {
    return (
      <div className="blockContainer" id={data.id}>
        {data.map((subData: any) => {
          return (
            <AgentOutContainer
              key={subData.id}
              data={subData}
              value={subData.text}
              copyAction={copyAction}
            ></AgentOutContainer>
          );
        })}
      </div>
    );
  };

  // 复制文本
  const copyAction = (value: any) => {
    const data = value.data;
    let content = "";
    data.forEach((e: any) => {
      if (e.type == "text") {
        content += e.value;
      }
    });
    if (content.length > 0) {
      message.info("Copy successful.");
      navigator.clipboard.writeText(content);
    }
  };

  // 复制除text外的模块
  const copyCardAction = (value: any) => {
    const {
      id,
      type = "",
      title = "",
      continueData = "",
      content = ""
    } = value;
    const barType = AgentTypeDoc.includes(type) ? "doc" : type;
    const cData = AgentTypeDoc.includes(type) ? content : continueData;

    message.destroy();
    if (copyDataListRef.current.length > 4) {
      message.warning(
        "Sorry, the maximum number of questions you can ask has been reached."
      );
      return;
    }

    if (copyDataListRef.current.some((i: any) => i.id === id)) {
      message.warning(
        "This module already exists in the continue questions section. Please do not add it again."
      );
      return;
    }

    // table graph code img latex模块继续提问的数据结构，传给ai-bar
    const data = {
      id: id,
      type: barType,
      title: title,
      name: `${barType}${copyDataListRef.current.length + 1}`,
      content: cData,
      answer: continueData
    };
    const copyData: any = Object.assign(data, { type, isContinueAsk: true });
    // 继续提问模块的数据，用以去重判断和页面中的展示
    copyDataListRef.current = [...copyDataListRef.current, copyData];
    console.log("----copy end------", copyDataListRef.current);
    setCopyData(data);
  };

  const clickCardLinkAction = (data: any) => {
    if (data.url) {
      window.open(data.url, "_blank");
    }
  };

  const getSubCard = (list: any) => {
    return (
      <div className="sub-card">
        {list.map((e: any, idx: number) => {
          const { type } = e;

          return (
            <React.Fragment key={idx}>
              {/* 换行标志，每一种类型的元素都在新行展示 */}
              {idx > 0 && type !== list[idx - 1].type && (
                <div className="break-line" />
              )}
              <div className={`sub-card-${type}`} key={`${type}_${idx}`}>
                {getSubCardContainer(e)}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const getSubCardContainer = (data: any) => {
    const { type, answer, isContinueAsk } = data;

    if (type == AgentTypeMarkdown || type === "table" || type === "code") {
      return (
        <PMMarkdown
          markdown={data}
          copyAction={(value: any) => {
            copyCardAction(value);
          }}
          isContinueAsk={isContinueAsk}
        />
      );
      // document(meta/thunk/exa/sentence)
    } else if (AgentTypeDoc.includes(type)) {
      return (
        <AgentOutCardDocument
          key={data.id}
          data={answer}
          value={data}
          isContinueAsk={isContinueAsk}
          copyAction={(value: any) => {
            copyCardAction(value);
          }}
          clickAction={clickCardLinkAction}
        />
      );
      // graph
    } else if (type === AgentTypeGraph) {
      return (
        <AgentOutCardGraph
          data={answer}
          copyAction={(value: any) => {
            copyCardAction(value);
          }}
        />
      );
      // error
    } else if (type === AgentEventError) {
      const id = agentRequestUtil.getRandomData();
      return (
        <div className="error-card" key={id}>
          <ExclamationCircleOutlined />
          <span dangerouslySetInnerHTML={{ __html: answer }} />
        </div>
      );
      // div
    } else if (type === "div") {
      const id = agentRequestUtil.getRandomData();
      return (
        <div key={id}>
          <div dangerouslySetInnerHTML={{ __html: data.data }} />
        </div>
      );
      // placeholder
    } else if (type === "placeholder") {
      return (
        <div key={data.id}>
          <Spin />
        </div>
      );
    } else if (type == CardTypeProject) {
      if (data.datas && data.datas.length > 0) {
        const codeInfo = data.datas[0];
        return (
          <div key={codeInfo.id} className="projectInfo">
            <div>
              {codeInfo.title}
              <span
                onClick={() => {
                  window.open(codeInfo.url);
                }}
              >
                {codeInfo.clickTitle}
              </span>
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  // 获取ai-bar中继续提问模块的data-id
  const checkSectionAttributes = (htmlString: any, dataArray: any) => {
    const sectionRegex = /<section\s+id="([^"]+)"[^>]*>/g;
    const matchedItems: any = [];
    let match;

    while ((match = sectionRegex.exec(htmlString)) !== null) {
      const id = match[1];

      for (const item of dataArray) {
        if (item.id === id) {
          matchedItems.push({ ...item });
          break;
        }
      }
    }
    // 返回匹配的对象数组
    return matchedItems;
  };

  // 编辑器内容高度改变
  const contentChange = (height: number, hasContinuAskContent: any) => {
    if (!hasContinuAskContent) return;

    const aiBarcontent = dealAiBarData(hasContinuAskContent);
    copyDataListRef.current = checkSectionAttributes(
      aiBarcontent,
      copyDataListRef.current
    );
  };

  // 开启新会话，把当前页面内容置为空
  const startNewConversation = () => {
    resetAllRequestData();
    setConversationId("");
    streamDataListRef.current = [];
    setHistoryList([]);
    setDataList([]);
    setEditable(true);
  };

  const addToPoolingTaskPool = (chatId: string, taskId: string) => {
    console.log("addTask之前==", Store.getState().taskSlice.taskList);
    Store.dispatch(
      addTask({
        chatId: chatId,
        taskId: taskId
      })
    );
    console.log("addTask之后==", Store.getState().taskSlice.taskList);
  };

  useEffect(() => {
    //检查任务是否变化
    const checkTaskList = () => {
      const taskList = Store.getState().taskSlice.taskList;
      const index = taskList.findIndex(
        (item: any) => item.chatId === conversationId
      );
      if (index !== -1) {
        const task: any = taskList[index];
        currentEventRef.current = "generation status:";
        currentNodeTypeRef.current = task.step || "";
        currentNodeTitleRef.current = task.title || "";
        // console.log("任务变化-----", task);
        if (task.step == "completed") {
          taskGenerationProjectCompleted();
        }
      }
    };

    //检测任务是否停止
    const checkStopTask = () => {
      const stopId = Store.getState().taskSlice.stopTaskId;
      if (stopId == currentChatTaskIdRef.current) {
        stopAgentApi();
      }
    };

    //初始化时检查是否有任务
    checkTaskList();

    //监听taskList变化
    const unsubscribe = Store.subscribe(() => {
      checkTaskList();
      checkStopTask();
    });
    return () => unsubscribe();
  }, []);

  //工程完成
  const taskGenerationProjectCompleted = () => {
    removeStreamListPlaceholder();
    const arr = [
      {
        id: currentThrunkIdRef.current,
        type: CardTypeProject,
        nodeType: currentNodeTypeRef.current,
        chrunkTitle: currentNodeTitleRef.current,
        thrunkEvent: currentEventRef.current,
        datas: [
          {
            type: CardTypeProject,
            url: "http://3.93.31.83:8443/?folder=/workspace",
            title: "The project has been generated,",
            clickTitle: "click to jump to the project"
          }
        ]
      }
    ];
    streamDataListRef.current = [...streamDataListRef.current, ...arr];
    setRefreshFlag((pre) => pre + 1);
  };

  return (
    <div className="agent">
      <div className="agent-top">
        <Tooltip
          placement="right"
          arrow={false}
          title="Start a new conversation"
          className="start-new-conversation-tooltip"
        >
          <div
            className="start-new-conversation-box"
            onClick={startNewConversation}
          >
            <PlusCircleOutlined />
          </div>
        </Tooltip>
        <div className="top-back" onClick={goBack}>
          <Image src={arrow_left} preview={false} />
          <span>AI Assistant / Interaction page with input</span>
        </div>
      </div>
      <div className="agent-content" id="my-app" ref={containerRef}>
        <div className="agent-content-left">
          {historyList &&
            historyList.map((value: any) => {
              return (
                <AgentOutChatContainer
                  data={value}
                  key={value.id}
                  node={getHistorySubContainer(value.data)}
                  onReGenerating={onReGenerating}
                  onCopy={copyAction}
                ></AgentOutChatContainer>
              );
            })}
          {/* user/agent数据 */}
          {dataList &&
            (dataList as any).map((value: any) => {
              return (
                <AgentOutChatContainer
                  data={value}
                  key={value.id}
                  node={getSubCard(value.data)}
                  onReGenerating={onReGenerating}
                  onCopy={copyAction}
                ></AgentOutChatContainer>
              );
            })}
          {/* 正在流式输出的数据 */}
          {refreshFlag !== 0 && streamDataListRef.current.length > 0 && (
            <AgentOutChatContainer
              data={""}
              node_event={currentEventRef.current}
              node_title={currentNodeTitleRef.current}
              node={getSubCard(streamDataListRef.current)}
            ></AgentOutChatContainer>
          )}
        </div>
        <div className="agent-content-right"></div>
        <div className="agent-bar-container">
          <AiBar
            className="agent-bar"
            showFullScreen={true}
            editable={editable}
            callBackFun={editorCallBack}
            copyData={copyData}
            contentChange={contentChange}
            stopGenerateCallBack={onStopGenerating}
          />
        </div>
      </div>
    </div>
  );
});
AgentOut.displayName = "AgentOut";

export default AgentOut;
