import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Tag, Modal, Button, message, Tooltip } from "antd";
import classnames from "classnames";
import {
  SendGetRequest,
  SendPutRequest,
  Api,
  Constant,
  Utils
} from "@/common/";
import TagAndColor from "./config";
import "./index.scss";
import logo from "@/assets/images/sider_logo.png";
import arrow from "@/assets/icons/arrow_down.png";

const { Search } = Input;

const CreateTag = () => {
  const [openModal, setOpenModal] = useState(false);
  const [tagPopoverId, setTagPopoverId] = useState(0);
  const [tagsList, setTagsList] = useState<any>([]);
  const [selectTags, setSelectTags] = useState<any>([]);
  const [recommendTags, setRecommendTags] = useState<any>([]);
  const selectTagsRef = useRef(selectTags);
  const popoverId = useRef(0);
  const delTagId = useRef(null as any);
  const navigate = useNavigate();
  const userId = localStorage.getItem(Constant.LOCAL_KEY.userId);

  const goHomePage = () => {
    navigate("/");
  };

  const onSearch = () => {
    //
  };

  const onDelRecommendTag = (e: any, id: string) => {
    e.preventDefault();
    setOpenModal(true);
    delTagId.current = id;
  };

  const onSureDelRecommendTag = () => {
    const curRecTags = recommendTags.filter(
      (item: any) => item.id !== delTagId.current
    );

    setRecommendTags(curRecTags);
    setOpenModal(false);
  };

  const onDelTag = (id: string) => {
    const curSelTags = selectTags.filter((item: any) => item.id !== id);
    setOpenModal(false);
    setSelectTags(curSelTags);
  };

  const tagPopoverChange = (id: number) => {
    setTagPopoverId(id);
    popoverId.current = id;
  };

  const selectTag = (tagItem: any) => {
    const curTags = selectTagsRef.current;
    const { id } = tagItem;
    let newTags: object[] = [];

    // 如果当前选中的标签已经在推荐标签中，则删除推荐标签中的该标签
    if (recommendTags.find((item: any) => item.id === id)) {
      newTags = recommendTags.filter((c: any) => c.id !== id);
      setRecommendTags(newTags);
      return;
    }

    // 如果当前选中的标签已经在已选标签中，则删除已选标签中的该标签
    if (selectTags.find((item: any) => item.id === id)) {
      newTags = curTags.filter((c: any) => c.id !== id);
    } else {
      newTags = curTags.concat(tagItem);
    }
    setSelectTags(newTags);
    selectTagsRef.current = newTags;
  };

  const mergeAndRemoveOthers = (arr1: any, arr2: any) => {
    let mergedArray = [...arr1, ...arr2];

    mergedArray = mergedArray.map((item) => {
      const { id } = item;

      return id;
    });

    return mergedArray;
  };

  const setUserTag = (callBack: any) => {
    SendPutRequest(`${Api.userTag}${userId}`, {
      tags: mergeAndRemoveOthers(selectTags, recommendTags)
    }).then((res: any) => {
      const {
        status: { code, desc }
      } = res;

      if (code === Constant.HTTP_STATUS.SUCCESS) {
        callBack();
      } else {
        message.error(desc);
      }
    });
  };

  const goSkip = () => {
    navigate("/assistant");
  };

  const goDone = () => {
    setUserTag(() => {
      navigate("/assistant");
    });
  };

  // 标签数据处理
  const buildTree = (data: any, parentId = 0) => {
    const tree: any = [];

    data.forEach((item: any) => {
      if (item.parentId === parentId) {
        const curKey = item.parentId === 0 ? "id" : "parentId";
        const children = buildTree(data, item.id);

        if (children.length) {
          item.children = children;
        }
        tree.push(
          Object.assign(
            {
              color:
                TagAndColor.find((c: any) => c.id === item[curKey])?.color ||
                "#5856D6"
            },
            item
          )
        );
      }
    });
    return tree;
  };

  // 推荐标签数据处理
  const renderRecommendTags = (data: any) => {
    const newData: any = [];

    data.forEach((item: any) => {
      const curKey = item.parentId === 0 ? "id" : "parentId";

      newData.push(
        Object.assign(
          {
            color:
              TagAndColor.find((c: any) => c.id === item[curKey])?.color ||
              "#5856D6"
          },
          item
        )
      );
    });

    return newData;
  };

  // 获取推荐标签
  const getTagsRecommend = () => {
    SendGetRequest(Api.tagsRecommend).then((res: any) => {
      const {
        data,
        status: { code, desc }
      } = res;

      if (code === Constant.HTTP_STATUS.SUCCESS) {
        setRecommendTags(renderRecommendTags(data));
      } else {
        message.error(desc);
      }
    });
  };

  // 获取标签列表
  const getTagsList = () => {
    SendGetRequest(Api.tagsList).then((res: any) => {
      const {
        data,
        status: { code, desc }
      } = res;

      if (code === Constant.HTTP_STATUS.SUCCESS) {
        setTagsList(buildTree(data));
      } else {
        message.error(desc);
      }
    });
  };

  const handleClickOutside = (event: any) => {
    const parentElement = event.target.closest(".tags-item-box");

    if (parentElement) {
      if (parentElement.dataset.id !== popoverId.current.toString())
        setTagPopoverId(0);
    } else {
      setTagPopoverId(0);
    }
  };

  // 注册监听事件，点击popover之外的区域关闭popover
  useEffect(() => {
    const hasUploaded = Utils.hasQueryParam("hasUploaded");

    getTagsList();
    hasUploaded && getTagsRecommend();

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="create-tag">
      <img src={logo} className="create-tag-logo" onClick={goHomePage} />
      <div className="create-tag-box">
        <div className="create-tag-back">
          <p>Step 3 of 3</p>
        </div>
        <h4 className="create-tag-title">Create Tag options</h4>
        <p className="create-tag-desc">
          Select the tags that interest you, so we can conveniently recommend
          <br />
          content that matches your preferences.
        </p>
        <div className="create-tag-main">
          <div className="create-tag-main-left">
            <Search
              className="search-tag"
              placeholder="Search"
              onSearch={onSearch}
            />
            <div className="create-tag-main-left-your-tags">
              {recommendTags.length > 0 && (
                <>
                  <h5>Your Tags</h5>
                  <p>
                    Based on your information, we recommend the following tags.
                    You can delete or keep them.
                  </p>
                  <div className="your-tags-list">
                    {recommendTags.map((item: any) => {
                      const { color, name, id } = item;

                      return (
                        <Tooltip placement="top" title={name} key={id}>
                          <Tag
                            className="sel-tags-item"
                            color={color}
                            closable
                            onClose={(e: any) => {
                              onDelRecommendTag(e, id);
                            }}
                          >
                            {name}
                          </Tag>
                        </Tooltip>
                      );
                    })}
                  </div>
                </>
              )}
              <h5>Picked nice!</h5>
              <div className="your-picked-tags-list-box">
                <div className="your-picked-tags-list">
                  {selectTags.map((item: any) => {
                    const { color, name, id } = item;

                    return (
                      <Tooltip placement="top" title={name} key={id}>
                        <Tag
                          className="sel-tags-item"
                          key={id}
                          color={color}
                          closable
                          onClose={() => {
                            onDelTag(id);
                          }}
                        >
                          {name}
                        </Tag>
                      </Tooltip>
                    );
                  })}
                </div>
                <Modal
                  title=""
                  className="tag-modal"
                  open={openModal}
                  onOk={onSureDelRecommendTag}
                  onCancel={() => setOpenModal(false)}
                  okText="Delete"
                  cancelText="Cancel"
                  getContainer={false}
                  closable={false}
                  centered={false}
                >
                  <p className="title">
                    Are you sure you want to delete this tag?
                  </p>
                  <p className="desc">This action cannot be undone</p>
                </Modal>
              </div>
            </div>
          </div>
          <div className="create-tag-main-right">
            <div className="create-tag-main-right-tags">
              {tagsList.map((item: any) => {
                const { id, color, name, children } = item;

                return (
                  <div className="tags-item-box" data-id={id} key={id}>
                    <div
                      className="tags-item"
                      style={{ backgroundColor: color }}
                      onClick={() => tagPopoverChange(id)}
                    >
                      <p>{name}</p>
                      <img
                        src={arrow}
                        className={classnames("arrow", {
                          "arrow-active": id === tagPopoverId
                        })}
                      />
                    </div>
                    <div
                      className={classnames("tag-content-list", {
                        "tag-content-list-show": id === tagPopoverId
                      })}
                    >
                      <div className="tag-content-list-box">
                        {children.map((citem: any) => {
                          const { id, name } = citem;

                          return (
                            <div
                              className="tag-content-list-item"
                              onClick={() => selectTag(citem)}
                              title={name}
                              key={id}
                            >
                              <span
                                className={classnames(
                                  "tag-content-list-item-icon",
                                  {
                                    "tag-content-list-item-icon-active":
                                      selectTags.find(
                                        (i: any) => i.id === id
                                      ) ||
                                      recommendTags.find(
                                        (i: any) => i.id === id
                                      )
                                  }
                                )}
                              ></span>
                              <span className="tag-content-list-item-name">
                                {name}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="create-tag-btn">
          <Button className="create-tag-btn-skip" onClick={goSkip}>
            Skip this step
          </Button>
          <Button
            className="create-tag-btn-done"
            type="primary"
            onClick={goDone}
          >
            Done
          </Button>
        </div>
        <div className="create-tag-btn">
          <Button className="create-tag-btn-skip" onClick={goSkip}>
            Skip this step
          </Button>
          <Button
            className="create-tag-btn-done"
            type="primary"
            onClick={goDone}
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateTag;
