import Api from "./api";
import { SendPostRequest } from "./axios";
import Constant from "./constant";

// const homepage_chat_open = "homepage_chat_open"; //以后做（官网）

//添加埋点信息-动作类型
export const addTrackingAction = (actionName: string) => {
  sendApi({
    event_name: actionName
  });
};

//添加埋点信息-页面类型
export const addTrackingPage = (pageName: string) => {
  sendApi({
    event_name: pageName
  });
};

const sendApi = (data: any) => {
  const params = {
    // ip: "",//涉及到隐私和安全问题，由后端自己获取
    useragent: navigator.userAgent,
    userid: localStorage.getItem(Constant.LOCAL_KEY.userId) || "",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timestamp: new Date().getTime(),
    ...data
  };
  SendPostRequest(Api.trackingEvent, params).then((res: any) => {
    const { status } = res;
    if (status.code === Constant.HTTP_STATUS.SUCCESS) {
      // console.log("埋点信息", data);
    } else {
      console.log("埋点失败", data);
    }
  });
};
