import React, { useRef } from "react";
import { AgentOutCardDataProps } from "../../common/commonData";
import "./index.scss";

//AST图
const AgentOutCardASTGraph: React.FC<AgentOutCardDataProps> = () => {
  const ref = useRef(null as any);

  return <div className="pm-card-code" ref={ref}></div>;
};

export default AgentOutCardASTGraph;
