import Api from "./api";
import { SendPutRequest } from "./axios";
import Constant from "./constant";

const Utils = {
  // 存储localStorage
  setLocalStorage: (key: string, value: string) => {
    localStorage.setItem(key, value);
  },
  // 获取localStorage
  getLocalStorage: (key: string) => {
    return localStorage.getItem(key);
  },
  // 删除localStorage
  removeLocalStorage: (key: string) => {
    if (key === "all") {
      localStorage.clear();
    } else {
      localStorage.removeItem(key);
    }
  },
  /**
   * 获取 URL 中查询参数的值
   * @param {string} paramName - 要获取的查询参数的名称
   * @param {string} [url=window.location.href] - 可选参数，默认是当前页面的URL
   */
  getQueryParam: (paramName: string, url = window.location.href) => {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    return params.get(paramName);
  },
  hasQueryParam: (paramName: string, url = window.location.href) => {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    return params.has(paramName);
  },

  /**
   * 是不是注册登录界面
   */
  isUserAuthPage: (path: string) => {
    return (
      path.startsWith("/login") ||
      path.startsWith("/register") ||
      path.startsWith("/resetPassword")
    );
  },

  //绑定邀请码
  bindInviCode: () => {
    const iniviCode = localStorage.getItem(Constant.LOCAL_KEY.invitaionCode);
    if ((iniviCode || "").length <= 0) {
      return;
    }
    SendPutRequest(Api.bindIniviCode + "?invi_code=" + iniviCode, {}).then(
      (res: any) => {
        const { status } = res;
        if (status.code === Constant.HTTP_STATUS.SUCCESS) {
          localStorage.removeItem(Constant.LOCAL_KEY.invitaionCode);
        }
      }
    );
  }
};

export default Utils;
