import { combineReducers } from "@reduxjs/toolkit";

import taskSlice from "./slices/taskSlice";
import userSlice from "./slices/userSlice";
import agentSlice from "./slices/agentSlice";
import commonSlice from "./slices/commonSlice";

const rootReducer = combineReducers({
  userSlice,
  taskSlice,
  agentSlice,
  commonSlice
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
