import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import "lib-flexible";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GlobalFloatTaskPoolWindow from "./components/taskPoolWindow";
import { Provider } from "react-redux";
import store from "../src/common/appStore";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
      <GlobalFloatTaskPoolWindow />
    </Provider>
  </BrowserRouter>
);

reportWebVitals();
