import React, { useRef } from "react";
import { AgentOutCardDataProps } from "../../common/commonData";
import PMMarkdown from "../PMMarkdown";
import "./index.scss";

const AgentOutCardCode: React.FC<AgentOutCardDataProps> = ({ data }) => {
  const ref = useRef(null as any);
  const [markdown, setMarkdown] = React.useState("");

  React.useEffect(() => {
    // 用于展示的 Markdown 字符串
    const markdownContent = `
      \`\`\`
        ${data.lang}
        ${data.value}
      \`\`\`
    `;
    setMarkdown(markdownContent);
  }, [data]);

  return (
    <div className="pm-card-code" ref={ref}>
      <PMMarkdown markdown={markdown}></PMMarkdown>
    </div>
  );
};

export default AgentOutCardCode;
