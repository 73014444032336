import React, { useCallback } from "react";
import { Image, Tooltip } from "antd";
import { AgentOutCardDataProps } from "../../common/commonData";
import "./index.scss";
import copyIcon from "@/assets/icons/copy.png";
// import bookmark from "@/assets/icons/agent-bookmark-icon.png";

const AgentOutCardDocument: React.FC<AgentOutCardDataProps> = ({
  data,
  isContinueAsk,
  copyAction,
  clickAction
}) => {
  const { id, image, title, authors, publishedDate, content } = data;

  const copy = useCallback((item: any) => {
    if (copyAction) {
      const d = Object.assign(item, { continueData: item });
      copyAction(d);
    }
  }, []);

  const click = useCallback((item: any) => {
    if (clickAction) {
      clickAction(item);
    }
  }, []);

  const getShowText = (text: any, length: number) => {
    if (!text) {
      return "---";
    }
    if ((text || "").length > length) {
      return text.slice(0, length) + "...";
    } else {
      return text;
    }
  };

  // const bookmarkAction = useCallback(() => {
  //   // bookmarkAction
  // }, []);

  return (
    <div className="pm-card-document">
      <div
        key={id}
        className="document-sub-container"
        onClick={() => click(data)}
      >
        {image && (
          <div className="pm-card-document-image">
            <Image src={image} alt="" preview={false} />
          </div>
        )}
        <div className="pm-card-document-title">{getShowText(title, 60)}</div>
        {/* 作者 */}
        <div className="pm-card-document-author">
          {(authors ?? []).slice(0, 3).map((item: any, index: number) => {
            return (
              <div key={index} className="pm-card-document-author-item">
                {item}
              </div>
            );
          })}
        </div>
        <div className="pm-card-document-date">
          Published:{publishedDate || "---"}
        </div>
        {/* 内容 */}
        <div className="pm-card-document-content">
          {getShowText(content, 150)}
        </div>
        {!isContinueAsk && (
          <div className="pm-card-document-bottom">
            {/* <Image
            src={bookmark}
            preview={false}
            width={25}
            onClick={() => bookmarkAction()}
          ></Image> */}
            <Tooltip
              placement="bottom"
              arrow={false}
              title="Continue asking questions about this module."
            >
              <Image
                src={copyIcon}
                preview={false}
                onClick={() => copy(data)}
              />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default AgentOutCardDocument;
