// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pm-card-file {
  overflow: hidden;
  margin-bottom: 0.05208rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  word-break: break-all;
}
.pm-card-file .pm-card-document-image {
  border-radius: 0.10417rem;
  background: #fff;
  object-fit: cover;
  overflow: hidden;
  margin-bottom: 0.05208rem;
  width: 1.30208rem;
}
.pm-card-file .pm-card-document-image img {
  width: 1.30208rem;
  height: 0.77604rem;
}
.pm-card-file .pm-card-document-file {
  padding: 0.10417rem;
  border-radius: 0.10417rem;
  background: #fff;
  color: var(--var-prismer-color3);
  font-size: 0.07292rem;
  margin-bottom: 0.05208rem;
  max-height: 0.52083rem;
  width: 1.30208rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/agentOut/component/AgentOutCardFile/index.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,yBAAA;EACA,aAAA;EACA,eAAA;EACA,WAAA;EACA,qBAAA;AACF;AACE;EACE,yBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,yBAAA;EACA,iBAAA;AACJ;AACI;EACE,iBAAA;EACA,kBAAA;AACN;AAGE;EACE,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,gCAAA;EACA,qBAAA;EACA,yBAAA;EACA,sBAAA;EACA,iBAAA;AADJ","sourcesContent":[".pm-card-file {\n  overflow: hidden;\n  margin-bottom: 10px;\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  word-break: break-all;\n\n  .pm-card-document-image {\n    border-radius: 20px;\n    background: #fff;\n    object-fit: cover;\n    overflow: hidden;\n    margin-bottom: 10px;\n    width: 250px;\n\n    img {\n      width: 250px;\n      height: 149px;\n    }\n  }\n\n  .pm-card-document-file {\n    padding: 20px;\n    border-radius: 20px;\n    background: #fff;\n    color: var(--var-prismer-color3);\n    font-size: 14px;\n    margin-bottom: 10px;\n    max-height: 100px;\n    width: 250px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
