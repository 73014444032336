import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Utils from "../utils";

const initialState: any = {
  loginRouteHistoryList: [] // 登录界面的路由历史记录
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    addRouteAboutLogin(state, action: PayloadAction<string>) {
      if (!Utils.isUserAuthPage(action.payload)) {
        state.loginRouteHistoryList = [];
      }
      state.loginRouteHistoryList.push(action.payload);
    }
  }
});

export const { addRouteAboutLogin } = commonSlice.actions;

export default commonSlice.reducer;
