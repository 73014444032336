// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/robotoRegular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/robotoThin.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: Roboto-Regular;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
@font-face {
  font-family: Roboto-Thin;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}
body {
  margin: 0;
  padding: 0;
  font-family: Roboto-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: 0;
  }
  ul {
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
    padding: 0;
  }
}
:root {
  --var-prismer-color1: #325cef;
  --var-prismer-color2: #7209b7;
  --var-prismer-color3: #0c0130;
  --var-prismer-color4: #868686;
  --var-prismer-color5: #fcfcfc;
  --var-prismer-color6: #9747ff;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,+DAA+D;AACjE;AACA;EACE,wBAAwB;EACxB,+DAA4D;AAC9D;AACA;EACE,SAAS;EACT,UAAU;EACV,2BAA2B;EAC3B,mCAAmC;EACnC,kCAAkC;EAClC;;;;;;IAME,SAAS;EACX;EACA;IACE,gBAAgB;IAChB,uBAAuB;IACvB,SAAS;IACT,UAAU;EACZ;AACF;AACA;EACE,6BAA6B;EAC7B,6BAA6B;EAC7B,6BAA6B;EAC7B,6BAA6B;EAC7B,6BAA6B;EAC7B,6BAA6B;AAC/B","sourcesContent":["@font-face {\n  font-family: Roboto-Regular;\n  src: url(\"./assets/fonts/robotoRegular.ttf\") format(\"truetype\");\n}\n@font-face {\n  font-family: Roboto-Thin;\n  src: url(\"./assets/fonts/robotoThin.ttf\") format(\"truetype\");\n}\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: Roboto-Regular;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  h1,\n  h2,\n  h3,\n  h4,\n  h5,\n  p {\n    margin: 0;\n  }\n  ul {\n    list-style: none;\n    padding-inline-start: 0;\n    margin: 0;\n    padding: 0;\n  }\n}\n:root {\n  --var-prismer-color1: #325cef;\n  --var-prismer-color2: #7209b7;\n  --var-prismer-color3: #0c0130;\n  --var-prismer-color4: #868686;\n  --var-prismer-color5: #fcfcfc;\n  --var-prismer-color6: #9747ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
