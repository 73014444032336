import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { setStopTaskId, updateTask } from "../../common/slices/taskSlice";
import store from "../../common/appStore";
import { useNavigate } from "react-router-dom";
import { Api, Constant, SendGetRequest, Store } from "@/common";
import { Button, Progress } from "antd";

const TaskPoolWindow = () => {
  const navigate = useNavigate();
  const tasksRef = useRef<any[]>([]);
  const [refreshTask, setRefreshTask] = useState(0);
  const showMenuStatusRef = useRef<any>();
  const [menuShowMiniStatus, setMenuShowMiniStatus] = useState(false);
  const [showTaskFloatMenu, steShowTaskFloatMenu] = useState(false);
  const executingTaskIdRef = useRef<string>("");
  const processFinishValue = Constant.TASK_FINISH_VALUE;

  useEffect(() => {
    //初始化时检查是否有任务
    checkTaskList();

    //监听taskList变化
    const unsubscribe = store.subscribe(() => {
      checkTaskList();
    });
    return () => unsubscribe();
  }, []);

  //检查任务是否变化
  const checkTaskList = () => {
    const taskList = store.getState().taskSlice.taskList;
    const taskIds = taskList.map((e: any) => e.taskId);
    const oldTaskIds = tasksRef.current.map((e: any) => e.taskId);
    if (taskIds.length == 0 && oldTaskIds.length == 0) {
      return;
    }
    tasksRef.current = taskList;
    setRefreshTask((pre: any) => pre + 1);
    checkFloatMenuShowStatus();
    if (executingTaskIdRef.current.length == 0) {
      recycleTaskPoolTimes = 0;
      pollingTasksPool();
    }
  };

  //检查菜单的展示状态
  const checkFloatMenuShowStatus = () => {
    if (showMenuStatusRef.current == false) {
      steShowTaskFloatMenu(false);
      return;
    }
    const hasData = tasksRef.current.length > 0;
    if (!hasData) {
      steShowTaskFloatMenu(false);
      return;
    }
    tasksRef.current = tasksRef.current.filter(
      (e: any) => !e.stop || e.stop == false
    );
    steShowTaskFloatMenu(tasksRef.current.length > 0 ? true : false);
  };

  //开始轮询任务池
  let recycleTaskPoolTimes = 0;
  const pollingTasksPool = () => {
    if (tasksRef.current.length == 0) {
      return;
    }

    //最多循环2000次，200*2000=40
    if (recycleTaskPoolTimes >= 2000) {
      return;
    }

    //取出第一个任务
    console.log("轮询任务池", tasksRef.current);
    const indexExecuting = tasksRef.current.findIndex((e: any) => {
      console.log("轮询任务池", e);
      return e.schedule < processFinishValue;
    });

    if (indexExecuting < 0) {
      console.log(
        "当前任务池没有需要执行的任务",
        indexExecuting,
        tasksRef.current
      );
      return;
    }
    const executingTask = tasksRef.current[indexExecuting];
    executingTaskIdRef.current = executingTask.taskId;

    const stopId = Store.getState().taskSlice.stopTaskId;
    if (stopId == executingTaskIdRef.current) {
      return;
    }

    // 请求task的数据
    const url =
      Api.generationProjectCodeStatus + "/" + executingTaskIdRef.current;
    SendGetRequest(url, {}).then((res: any) => {
      if (res.status.code === Constant.HTTP_STATUS.SUCCESS) {
        recycleTaskPoolTimes++;
        const data = res.data;
        console.log("任务池轮询成功data=", data);
        //更新进度展示
        const index = tasksRef.current.findIndex(
          (e: any) => e.taskId == executingTask.taskId
        );
        if (index > -1) {
          console.log("任务池轮询成功data=1==", data);
          try {
            if (data.status) {
              const newData = { ...tasksRef.current[index] };
              if (data.status.indexOf("completed") > -1) {
                newData.schedule = 1.0;
                newData.projectUrl = Constant.AGENT.projectUrl;
                console.log("进度完成,自动触发更新", data, executingTask);
                executingTaskIdRef.current = "";
              } else {
                const statusInfo = JSON.parse(data.status);
                newData.schedule = statusInfo.schedule;
                newData.step = statusInfo.step;
                //initiate_context
                if (statusInfo.step == "initiate_context") {
                  newData.title = statusInfo.content;
                }
              }
              tasksRef.current[index] = newData;
              setRefreshTask((pre: any) => pre + 1);
              store.dispatch(updateTask(newData));
            } else {
              console.log("任务池轮询失败====", res);
            }
          } catch (e) {
            //
            console.log("任务池轮询===解析失败====", res);
          }
        }

        setTimeout(() => {
          pollingTasksPool();
        }, 2000);
      } else {
        console.log("任务池轮询失败", res);
      }
    });
  };

  //跳转到agentout界面
  const onClickCard = (data: any) => {
    navigate(`/agentOut?id=${data.chatId}`);
  };

  const stop = (data: any) => {
    const newData = { ...data };
    newData.stop = true;
    executingTaskIdRef.current = "";
    store.dispatch(updateTask(newData));
    store.dispatch(setStopTaskId(newData.taskId));
  };

  const menuContent = () => {
    if (menuShowMiniStatus) {
      return miniTaskContent();
    } else {
      return normalTaskContent();
    }
  };

  const normalTaskContent = () => {
    return (
      <div className="floatMenu">
        <div className="floatMenuHeader">
          {/* <div className="floatMenuTitle">Task Center</div> */}
          {/* <div
            className="close"
            onClick={() => {
              setMenuShowMiniStatus(!menuShowMiniStatus);
            }}
          >
            最小化
          </div> */}
        </div>
        {refreshTask > -1 &&
          tasksRef.current.map((e: any) => {
            return (
              <div
                className="menuCard"
                key={e.taskId}
                onClick={() => onClickCard(e)}
              >
                <div className="taskTitle">
                  {e.taskTitle || "Genneration code"}
                </div>
                <div style={{ marginTop: "10px" }}></div>
                <Progress
                  size={"small"}
                  percent={e.schedule * 100}
                  showInfo={false}
                  strokeColor={"#325CEF"}
                />
                <div className="processDes">{e.step}</div>
                {e.schedule >= processFinishValue ? (
                  <div className="projectInfo">
                    <div
                      onClick={() => {
                        window.open(e.projectUrl, "_blank");
                      }}
                    >
                      Click to jump to the project
                    </div>
                  </div>
                ) : (
                  <Button
                    className="stop"
                    type="primary"
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      stop(e);
                    }}
                  >
                    Stop
                  </Button>
                )}
              </div>
            );
          })}
      </div>
    );
  };
  const miniTaskContent = () => {
    return (
      <div
        className="floatMenu"
        onClick={() => {
          setMenuShowMiniStatus(!menuShowMiniStatus);
        }}
      >
        当前任务{tasksRef.current.length}个
      </div>
    );
  };

  return (
    <div>
      {showTaskFloatMenu ? (
        <div className="taskFloatMenu" id="taskFloatMenu">
          {menuContent()}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TaskPoolWindow;

// const areElementsTheSame = (List1: TaskData[], List2: TaskData[]) => {
//   const areElementsTheSame =
//     List1.length === List2.length &&
//     List1.every((item, index) => {
//       return (
//         item.taskId === List2[index].taskId &&
//         item.chatId === List2[index].chatId &&
//         item.stop === List2[index].stop &&
//         item.taskStatus === List2[index].taskStatus
//       );
//     });
//   console.log("判断task已经变化=", List1, List2, areElementsTheSame);
//   return areElementsTheSame;
// };
