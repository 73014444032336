import React from "react";
import UserAuth from "../userAuth";
import "./index.scss";

const ResetPassword = () => {
  return (
    <div className="resetPassword">
      <UserAuth />;
    </div>
  );
};

export default ResetPassword;
