import React, { useEffect, useState, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import classnames from "classnames";
import { Spin, message } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import { SendPostRequest, Api, SendGetRequest, Constant } from "@/common/";
import { Audio } from "@/components/";
import { AiBarConfig, NameAndIcon } from "./config";
import "./index.scss";
import imageLine from "@/assets/icons/ai_bar_image_line.png";
import goSearchLine from "@/assets/icons/ai_bar_go_search_line.png";
import audio from "@/assets/icons/ai_bar_audio_line.png";
import fullScreen from "@/assets/icons/ai_bar_full_screen.png";
import closeFullScreen from "@/assets/icons/ai_bar_close_full_screen.png";
import stopIcon from "@/assets/icons/ai_stop.png";

const AiBar = (props: AiBarConfig) => {
  const defaultAlign = "bottom";
  const tips =
    "Prismer AI may also make mistakes. Please verify important information.";
  const {
    className,
    placeholder = "Ask me whatever you like.",
    copyData,
    align = defaultAlign,
    editable = true,
    callBackFun,
    contentChange
  } = props;
  const color = "#0C0130";
  const [tinyEditor, setTinyEditor] = useState<any>(null);
  const editorRef = useRef<any>(null);
  const [editorFullScreen, setEditorFullScreen] = useState(false);
  const editorIsFullScreen = useRef<any>(null);
  const editorShouldShow = useRef<any>(null);
  const [editorIsShow, setEditorIsShow] = useState(false);
  let processTimer: any;
  const [isUploading, setIsUploading] = useState(false);
  const [isEditable, setIsEditable] = useState(editable);
  const [isSending, setIsSending] = useState(false);
  const quickSendHint = "shift+enter to send";
  // const quickCreateHint = "ctrl+shift+n to create";
  // tinyMce config
  const initConfig: any = {
    selector: "textarea",
    license_key: "gpl",
    menubar: false,
    min_height: 100,
    max_height: 300,
    placeholder: placeholder,
    auto_resize: true,
    highlight_on_focus: true,
    autofocus: true,
    plugins: [
      "autolink",
      "autosave",
      "save",
      "link",
      "codesample",
      "autoresize",
      "fullscreen"
    ],
    toolbar: "undo redo link fileupload codesample",
    quickbars_insert_toolbar: false, // 禁用内容插入工具栏
    quickbars_selection_toolbar: false, // 禁用选择文本时出现的工具栏
    contextmenu: "", // 右键上下文菜单
    content_style: `
      body { 
        font-size:14px;
        color:${color};
        line-height:20px;
        margin:1rem;
        // font-family: Roboto-Regular;
        font-weight:300;
        &::-webkit-scrollbar {
          width: 4px;
          margin-right: 10px;
        }
        &::-webkit-scrollbar-track {
          background-color: none;
        }
        &::-webkit-scrollbar-thumb {
          border-radius:20px;
          background-color: rgba(37, 64, 235,0.1);
        }
      }
      .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before{
        color:${color};
        text-indent:5px;
        font-size:14px;
        // font-family: Roboto-Thin;
      }
      img{
        width:100px;
        height:100px
        object-fit: cover;
      } 
      .mce-content-body [contentEditable=false][data-mce-selected]{outline:none} :not(pre)>code[class*=language-], pre[class*=language-]{
        background:#fafafa;
        border-radius:5px;
        font-size:10px;
      } 
      p{
        margin-block-start:0;
        margin-block-end:0; 
      }
      a{
        color:${color};
      } 
      .file-name{
        text-underline:1px;
        border-bottom:1px solid ${color};
      }
      .copy-content-item-img{
        max-width:20px !important;
        height:auto !important;
      }`,
    autoresize_bottom_margin: 0,
    autoresize_overflow_padding: 0,
    setup: function (editor: any) {
      // 自定义上传/拖拽文件图片方法
      tinyUploadFile(editor);
      tinyDropFile(editor);
      // 监听键盘按下事件
      editor.on("keyup", (e: any) => {
        editorChange(e, editor);
      });
      // 【Shift】+【Enter】发起agent
      editor.on("keydown", (e: any) => {
        if (e.shiftKey && e.key === "Enter") {
          e.preventDefault();
          callBack();
        }
      });
    }
  };

  useEffect(() => {
    setIsEditable(editable);
  }, [editable]);

  // 自定义上传文件toolbar
  const tinyUploadFile = (editor: any) => {
    editor.ui.registry.addButton("fileupload", {
      icon: "image",
      tooltip: "insert file",
      onAction: () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");

        input.onchange = function (e: any) {
          fileInputChange(e, true);
        };

        input.click();
      }
    });
  };

  // 自定义拖拽文件
  const tinyDropFile = (editor: any) => {
    editor.on("drop", function (e: any) {
      e.preventDefault();
      e.stopPropagation();

      const files = e.dataTransfer.files;

      if (files.length > 0) {
        const file = files[0];

        setFileIntoTiny(e, file, true);
      }
    });
  };

  /*
   * 获取文件/图片id
   * callBack: 拿到id后的回调函数
   */
  const getUploadId = (file: any, callBack: (id: string) => void) => {
    setIsUploading(true);
    SendPostRequest(
      Api.upload,
      {
        file: file
      },
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ).then((res: any) => {
      const {
        status: { code, desc },
        data: { id }
      } = res;

      if (code === Constant.HTTP_STATUS.SUCCESS) {
        callBack(id);
      } else {
        setIsUploading(false);
        message.error(desc);
      }
    });
  };

  // 获取文件解析状态
  const getProcess = (id: string, callBack: any) => {
    new Promise(() => {
      SendGetRequest(Api.uploadProcess, {
        id: id
      }).then((res: any) => {
        const suc = 1;
        const {
          status: { code, desc },
          data: { chunk_status, document_id }
        } = res;

        if (code === Constant.HTTP_STATUS.SUCCESS) {
          callBack(chunk_status === suc, document_id);
        } else {
          clearInterval(processTimer);
          setIsUploading(false);
          message.error(desc);
        }
      });
    });
  };

  /*
   * --input change事件--
   * e: input 上传文件使用
   * isEditor: 是否是tinyMce内置功能
   */
  const fileInputChange = (e: any, isEditor?: boolean) => {
    const file = e.target.files[0];
    const sizeInMB = file.size / 1024 / 1024;
    const iframe: any = document.querySelector(".tox-edit-area__iframe");
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    editorRef.current.focus();

    // 文件上传个数限制（5个）
    if (iframeDocument) {
      const imgs = iframeDocument.querySelectorAll(".insert-img");
      const files = iframeDocument.querySelectorAll("em");
      const imgLength = imgs ? imgs.length : 0;
      const fileLength = files ? files.length : 0;

      if (imgLength + fileLength > 4) {
        message.error("Sorry, you can upload up to 5 files.");
        return;
      }
    }

    // 文件大小限制10MB
    if (sizeInMB > 10) {
      message.error("Please upload files smaller than 10MB");
    } else {
      setFileIntoTiny(e, file, isEditor);
    }
  };

  /*
   * --将文件/图片设置为tinymce内容--
   * e: input 上传文件使用
   * file: 文件信息
   * isEditor: 是否是tinyMce内置功能
   * ".pdf", ".gz", ".tar", ".zip", ".tex"类型文件需要获取解析进度
   */
  const setFileIntoTiny = (e: any, file: any, isEditor?: boolean) => {
    const fileName = file.name;
    const fileType = file.type;
    const validExtensions = [".pdf", ".gz", ".tar", ".zip", ".tex"]; // 需要获取进度的文件类型
    const isValid = validExtensions.some(function (extension: string) {
      return fileName.endsWith(extension);
    });
    const url = URL.createObjectURL(file);
    let dom;

    const setUploadDom = (id: string, isEditor?: boolean) => {
      if (fileType.indexOf("image") === -1) {
        dom = `<i class="file-name" data-id=${id}>${file.name}</i>`;
      } else {
        dom = `<img class="insert-img" src=${url} data-id=${id}>`;
      }

      editorRef.current.execCommand("mceInsertContent", false, dom);
      setIsUploading(false);
      if (!isEditor) {
        e.target.value = "";
      }
    };

    getUploadId(file, (id: string) => {
      if (isValid) {
        processTimer = setInterval(() => {
          getProcess(id, (bool: boolean, docId: string) => {
            setIsUploading(!bool);
            if (bool) {
              clearInterval(processTimer);
              setUploadDom(docId, isEditor);
            }
          });
        }, 500);
      } else {
        setUploadDom(id, isEditor);
      }
    });
  };

  // 获取html中某个标签出现的次数
  const countTagOccurrences = (htmlString: string, tagName: string) => {
    const regex = new RegExp(`<${tagName}\\b[^>]*>`, "gi");
    const matches = htmlString.match(regex);

    return matches ? matches.length : 0;
  };

  // 用来判断ai-bar中的内容是否包含继续提问的模块
  const containsSectionTag = (inputString: string) => {
    const sectionTagRegex = /<section(?:\s[^>]*)?>.*?<\/section>/i;

    return sectionTagRegex.test(inputString);
  };

  // tinyMce change
  const editorChange = (e: any, editor: any) => {
    const content = editor.getContent();
    // 输入内容是否超过一行，25为tinyMce内置的行高
    const isBreakLine =
      editor.selection.getSel().focusNode.parentElement.clientHeight > 25 ||
      countTagOccurrences(content, "p") > 1 ||
      countTagOccurrences(content, "img") > 0;

    editorShouldShow.current = isBreakLine;
    setEditorIsShow(editorIsFullScreen.current ? true : isBreakLine);

    callBackEditorHeigh(editor);
  };

  // 暴露编辑器高度和当前编辑器内继续提问的内容
  const callBackEditorHeigh = (editor: any) => {
    let height = 0;
    const editorData = editor.getContent();
    const content = containsSectionTag(editorData) ? editorData : null;

    if (editor.editorContainer.clientHeight <= 110) {
      height = 90;
    } else {
      if (editorShouldShow.current) {
        height = editor.editorContainer.clientHeight + 40;
      } else {
        height = 90;
      }
    }
    contentChange && contentChange(height, content);
  };

  // tinyMce 最大化
  const tinyFullScreen = () => {
    const b = !editorIsFullScreen.current;
    const bool: boolean = b ? true : editorShouldShow.current ? true : false;

    editorRef.current.execCommand("mceFullScreen");
    setEditorFullScreen(b);
    editorIsFullScreen.current = b;
    setEditorIsShow(bool);
  };

  // 音频转文字
  const transformAudioToText = (audioBlob: string) => {
    setIsUploading(true);
    SendPostRequest(
      Api.audioTranscriptions,
      { file: audioBlob, model: "large-v3", language: "zh" },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          noStatusCode: true
        }
      }
    ).then((res: any) => {
      const {
        data: { text }
      } = res;

      editorRef.current &&
        editorRef.current.execCommand(
          "mceInsertContent",
          false,
          `<p>${text}</p>`
        );
      setIsUploading(false);
    });
  };

  const getCopyDom = (data: any) => {
    const { title, icon, content, id } = data;

    return (
      <section id={id} title={title} data-id={content}>
        <img className="copy-content-item-img" src={icon}></img>
      </section>
    );
  };

  // 暴露回调
  const callBack = () => {
    const content = editorRef.current.getContent();

    if (isUploading || !isEditable || isSending) return;
    if (content === "") {
      message.destroy();
      message.error("Please ask something");
    } else {
      setIsSending(true);
      editorRef.current.setContent("");
      setEditorIsShow(false);
      callBackFun && callBackFun(content);
      callBackEditorHeigh(editorRef.current);
      setIsSending(false);
    }
  };

  const stopCallBack = () => {
    props.stopGenerateCallBack && props.stopGenerateCallBack();
  };

  // 输入框变编辑器
  useEffect(() => {
    const iframeBox: any = document.querySelector(".tox-sidebar-wrap");
    const header = document.querySelector(
      ".tox-editor-header"
    ) as HTMLDivElement;
    const fileUploadBtn = document.querySelector(
      ".ai-bar-upload-box"
    ) as HTMLDivElement;

    if (!iframeBox || !header || !fileUploadBtn) return;

    iframeBox.classList.toggle("tox-sidebar-wrap-active", editorIsShow);
    header.style.opacity = editorIsShow ? "1" : "0";
    fileUploadBtn.style.display = editorIsShow ? "none" : "block";
  }, [editorIsShow]);

  // 将用户copy的模块添加到tiny中
  useEffect(() => {
    if (
      !copyData ||
      (copyData && Object.keys(copyData).length === 0) ||
      !tinyEditor
    )
      return;

    const icon = NameAndIcon.find((i: any) => i.name === copyData.name)?.icon;
    const dataDom = ReactDOMServer.renderToString(
      getCopyDom(Object.assign(copyData, { icon }))
    );

    tinyEditor.execCommand("mceInsertContent", false, dataDom);
  }, [copyData, tinyEditor]);

  return (
    <div
      className={classnames("ai-bar ai-bar-theme-line", className, {
        "ai-bar-align-bottom": align === defaultAlign,
        "ai-bar-align-top": align !== defaultAlign
      })}
    >
      <div className="ai-bar-box">
        <div className="ai-bar-editor-box">
          <div
            className={classnames("ai-bar-editor-bg", {
              "ai-bar-editor-bg-isfullscreen":
                align !== defaultAlign && editorFullScreen,
              "ai-bar-editor-bg-active": editorIsShow,
              "content-show": tinyEditor
            })}
          >
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              onInit={(_evt: any, editor: any) => {
                setTinyEditor(editor);
                editor.focus();
                editorRef.current = editor;
              }}
              init={initConfig}
              onChange={editorChange}
            />
            {isUploading && <Spin className="ai-bar-loading" />}
            <img
              className={classnames("full-screen-btn", {
                "full-screen-btn-top": editorIsShow && align !== defaultAlign,
                "full-screen-btn-top-isfullscreen":
                  align !== defaultAlign && editorFullScreen,
                "edit-disable": !isEditable
              })}
              src={editorFullScreen ? closeFullScreen : fullScreen}
              onClick={tinyFullScreen}
              title="fullscreen"
              style={{ maxWidth: 15 }}
            />
          </div>
        </div>
        <div
          className={classnames("ai-bar-btn-box", {
            "ai-bar-active": align !== defaultAlign && editorIsShow,
            "content-show": tinyEditor,
            "ai-bar-btn-is-disable": isUploading
          })}
        >
          {/* go agent */}
          <div className="common-box">
            {isEditable ? (
              <img
                src={goSearchLine}
                className="ai-bar-go-search"
                onClick={callBack}
                title={quickSendHint}
              />
            ) : (
              <img
                src={stopIcon}
                className="ai-bar-go-search"
                onClick={stopCallBack}
              />
            )}
          </div>
          {/* audio */}
          <div
            className={classnames("ai-bar-audio common-box", {
              "ai-bar-btn-is-disable": isUploading || !isEditable
            })}
          >
            <Audio
              defaultIcon={<img src={audio} />}
              callTransformBack={transformAudioToText}
              isEditable={!isUploading && isEditable}
            />
          </div>
          {/* upload file */}
          <div
            className={classnames("ai-bar-upload-box common-box", {
              "edit-disable": isUploading || !isEditable
            })}
            title="insert file"
          >
            <div className="ai-bar-upload">
              {!isUploading && isEditable && (
                <input
                  className="ai-bar-upload-input"
                  type="file"
                  title=""
                  onChange={fileInputChange}
                />
              )}
              <img src={imageLine} className="ai-bar-image" />
            </div>
          </div>
        </div>
      </div>
      <div className="tips">
        {tips}
        <span style={{ marginLeft: "5px" }}>
          (You can use shit+enter to send quickly)
        </span>
      </div>
    </div>
  );
};

export default AiBar;
