// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pm-card-text-container .pm-card-text {
  color: var(--var-prismer-color3);
  display: flex;
  flex-direction: column;
  min-height: 0.18229rem;
  font-size: 0.07292rem;
  margin: 0.05208rem 0;
  overflow-x: scroll;
  max-width: 4.19271rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/agentOut/component/AgentOutCardText/index.scss"],"names":[],"mappings":"AACE;EACE,gCAAA;EACA,aAAA;EACA,sBAAA;EACA,sBAAA;EACA,qBAAA;EACA,oBAAA;EACA,kBAAA;EACA,qBAAA;AAAJ","sourcesContent":[".pm-card-text-container {\n  .pm-card-text {\n    color: var(--var-prismer-color3);\n    display: flex;\n    flex-direction: column;\n    min-height: 35px;\n    font-size: 14px;\n    margin: 10px 0px;\n    overflow-x: scroll;\n    max-width: 805px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
